import Vue from 'vue'
import VueRouter from 'vue-router'
import PsColors from '../views/color/PsColors.vue'
import PsCompanies from '../views/company/PsCompanies.vue'
import PsCompaniesContacts from '../views/company/PsCompaniesContacts.vue'
import PsConfiguration from '../views/configuration/PsConfiguration.vue'
import PsHome from '../views/PsHome.vue'
import PsLocalities from '../views/locality/PsLocalities.vue'
import PsLogin from '../views/PsLogin.vue'
import PsMarks from '../views/mark/PsMarks.vue'
import PsModels from '../views/model/PsModels.vue'
import PsProvinces from '../views/province/PsProvinces.vue'
import PsSearchDetail from '../views/searches/PsSearchesDetail.vue';
import PsSearches from '../views/searches/PsSearches.vue';
import PsSinisterDetail from '../views/sinisters/PsSinisterDetail.vue'
import PsSinisters from '../views/sinisters/PsSinisters.vue'
import PsUsers from '../views/user/PsUsers.vue'
import PsVehiclesFound from '../views/found/PsVehiclesFound.vue';
import PsVehiclesFoundDetail from '../views/found/PsVehiclesFoundDetail.vue';
import store from '../store'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Login', component: PsLogin },
  {
    path: '/home', name: 'Home', component: PsHome, redirect: 'sinisters', children: [
      { path: 'sinisters', component: PsSinisters },
      { path: 'sinisterDetail/:vehicleId/:sinisterId?', component: PsSinisterDetail },
      { path: 'configuration', component: PsConfiguration },
      { path: 'marks', component: PsMarks },
      { path: 'models', component: PsModels },
      { path: 'colors', component: PsColors },
      { path: 'provinces', component: PsProvinces },
      { path: 'localities', component: PsLocalities },
      { path: 'companies', component: PsCompanies },
      { path: 'companiesContacts/:companyId', component: PsCompaniesContacts },
      { path: 'users', component: PsUsers },
      { path: 'found', component: PsVehiclesFound },
      { path: 'foundDetail/:id', component: PsVehiclesFoundDetail },
      { path: 'searches', component: PsSearches },
      { path: 'searchDetail/:id', component: PsSearchDetail },
    ]
  },

]



const router = new VueRouter({
  mode: '',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path != '/') {
    store.dispatch('activeSession')
      .then(() => {
        next()
      })
      .catch(() => { next('/') })
  } else {
    next()
  }
})

export default router
