<template>
  <div class="psDrawerSelectTemplate">
    <el-drawer
      title="Seleccionar plantilla"
      :visible.sync="isOpenedDrawerSelectTemplate"
      direction="rtl"
      :before-close="handleClose"
      class="drawerTemplates"
      size="50%"
      v-if="isOpenedDrawerSelectTemplate"
    >
      <ps-select-image-template-item :item-number="`1`"></ps-select-image-template-item>
      <ps-select-image-template-item :item-number="`2`"></ps-select-image-template-item>
      <ps-select-image-template-item :item-number="`3`"></ps-select-image-template-item>
      <ps-select-image-template-item :item-number="`4`"></ps-select-image-template-item>
    </el-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import PsSelectImageTemplateItem from '@/views/sinisters/widgets/PsSelectImageTemplateItem.vue';

export default {
  name: 'PsDrawerSelectTemplate',
  components: { PsSelectImageTemplateItem },
  computed: {
    ...mapState(['isOpenedDrawerSelectTemplate', 'isUploadingImage']),
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SELECT_TEMPLATE']),
    handleClose() {
      this.SET_DRAWER_SELECT_TEMPLATE(false)
    },
  }
}

</script>

<style scoped>
  .drawerTemplates /deep/ #el-drawer__title > span { font-size: 22px; color: black; }

  .drawerTemplates /deep/ .el-drawer__body { 
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row; 
    justify-content: center; 
    align-items: flex-start;    
  }

  .psDrawerSelectTemplate /deep/ .el-drawer { max-width: 800px; }
</style>