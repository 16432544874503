<template>
  <div class="psVehicleFoundDetail">
    <ps-bar-buttons>
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="back"></ps-button>
      <!-- <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew"></ps-button>       -->
    </ps-bar-buttons>

    <div class="form">
      <h2>Vehiculo encontrado</h2>
      <div class="row">
        <div class="column" style="width: 45%">
          <div class="row">
            <div class="column size-s flex-column-align-left">
              <div class="label">Dominio</div>
              <div class="data">{{found.domain}}</div>
            </div>
            <div class="column size-m flex-column-align-left">
              <div class="label">Marca</div>
              <div class="data">{{found.mark}}</div>
            </div>
            <div class="column size-m flex-column-align-left">
              <div class="label">Modelo</div>
              <div class="data">{{found.model}}</div>
            </div>
          </div>
          <div class="row">
            <div class="column size-s flex-column-align-left">
              <div class="label">Nro chasis</div>
              <div class="data">{{found.chasisNumber}}</div>
            </div>
            <div class="column flex-row-align-left">
              <label for="code">Estado</label>
              <el-select 
                :value="found.status"
                size="mini"
                placeholder="Seleccionar un estado"
                @change="updateStatus($event)"
                class="size-m">
                <el-option value="Encontrado" label="Encontrado" ></el-option>
                <el-option value="Finalizado" label="Finalizado" ></el-option>
                <el-option value="Cancelado" label="Cancelado" ></el-option>
              </el-select>
            </div>
          </div>

          <hr />
          <h2>Ubicación</h2>
          <div class="row">
            <div class="column size-l flex-column-align-left">
              <div class="label">Calle</div>
              <div class="data">{{found.street}}</div>
            </div>           
            <div class="column size-s flex-column-align-left">
              <div class="label">Nro</div>
              <div class="data">{{found.number}}</div>
            </div>
          </div>
          <div class="row">
            <div class="column size-m flex-column-align-left">
              <div class="label">Ciudad</div>
              <div class="data">{{found.city}}</div>
            </div>           
            <div class="column size-m flex-column-align-left">
              <div class="label">Provincia</div>
              <div class="data">{{found.province}}</div>
            </div>
          </div>
          <div class="row">
            <div class="column size-m flex-column-align-left">
              <div class="label">Pais</div>
              <div class="data">{{found.country}}</div>
            </div>           
            <div class="column size-m flex-column-align-left">
              <div class="label">Coordenadas</div>
              <a :href="`https://www.google.com/maps/search/?api=1&query=${found.latitude}%2C${found.longitude}`" target="_blank"> 
                <el-button icon="el-icon-position" @click="goToGoogleMaps">Google Maps</el-button>
              </a>
            </div>
          </div>

          
        </div>
        <div class="column columnImage" style="width: 38%; height: 270px;">
          <a :href="urlImage" target="_blank">
            <img :src="urlImage" :alt="`Vehículo ${found.domain}`" class="image" style="object-fit: contain; height: 230px" />
          </a>
        </div>
      </div>

      <hr />
      <h2>Recuperador</h2>

      <div class="row">
        <div class="column size-m flex-column-align-left">
          <div class="label">Nombre</div>
          <div class="data">{{found.Recoverer.name}}</div>
        </div>           
        <div class="column size-m flex-column-align-left">
          <div class="label">Apellido</div>
          <div class="data">{{found.Recoverer.surname}}</div>
        </div>
        <div class="column size-m flex-column-align-left">
          <div class="label">Email</div>
          <div class="data">{{found.Recoverer.email}}</div>
        </div>
      </div>
      <div class="row">
        <div class="column size-m flex-column-align-left">
          <div class="label">Telefono</div>
          <div class="data">{{found.Recoverer.telephone}}</div>
        </div>
        <a :href="`https://api.whatsapp.com/send/?phone=%2B${found.Recoverer.telephone}`" class="linkWA" target="_blank">
          <div class="button">
            <img src="@/assets/images/iconWAGreen.svg" alt="" width="20" height="20" />
          </div>
        </a>
        <a :href="`tel:${found.Recoverer.telephone}`" target="_blank" class="linkWA" >
          <div class="button"><i class="el-icon-phone-outline" style="width: 20px; height: 20px; font-size: 1.20rem;"></i></div>
        </a>
      </div>

      <hr />
      <h2>Pedido de compañía aseguradora</h2>
      <el-table
        :data="found.request"
        height="250"
        style="width: 1200px;"
        size="mini">
          <el-table-column v-for="(item, index) in fieldsRequests" :key="index" :width="item.width" :prop="item.name" :label="item.label" sortable></el-table-column>
        </el-table>
    </div>
  </div>  
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'

import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  name: 'PsVehicleFoundDetail',
  components: { PsBarButtons, PsButton },
  computed: {
    ...mapState('found', ['found', 'fieldsRequests']),
    urlImage () {
      return this.found.imageUrl && this.found.imageUrl != '' ? this.found.imageUrl : 'https://images.circars.com.ar/placeholder.png';
    }
  },
  methods: {
    ...mapMutations('found', ['FOUNDS_FOUND', 'FOUNDS_FOUND_PROPERTIES']),
    ...mapActions('found', [ 'selectVehicleFound', 'updateVehicleFound']),
    back () {
      this.$router.go(-1)
      this.FOUNDS_FOUND({})
    },
    goToGoogleMaps() {
      console.log('google maps')
    },
    async updateStatus(state) {
      this.FOUNDS_FOUND_PROPERTIES({property: 'status', value: state});
      await this.updateVehicleFound()
    }
  },
  async created () {
    if(this.$route.params['id'] > 0) {
      await this.selectVehicleFound(this.$route.params['id'])
    } 
  },
  
}
</script>

<style scoped>

  .psVehicleFoundDetail { padding: 0 20px; height: 100%; display: flex; flex-direction: column; overflow-y: scroll; padding-bottom: 100px; }

  h2 {
    margin: 20px 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;    
  }

  .psBarButtons{ min-height: 40px; padding-left: 20px }
  .column { margin-right: 20px; }
  .check { margin: 13px 0}
  .row { margin-bottom: 10px;}

  .form { margin: 0 24px;}

  .columnImage > a { width: 100%;}
  .image { width: 100%; }

  hr { color: #eee; background-color: #eee; height: 1px; border: none}

  .linkWA { text-decoration: none; color: #606266}
  .button { padding: 12px 20px; border: 1px solid #DCDFE6; border-radius: 4px; line-height: 16px; display: flex; align-items: center; margin-right: 8px;}
  
</style>
