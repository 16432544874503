<template>
  <main class="psMain">
    <aside id="menu">
      <ps-item-menu icono="el-icon-truck" caracter="&#xe800;" item="Siniestros" @click.native="goTo('home/sinisters')" :isActive="sinisters"></ps-item-menu>
      <ps-item-menu icono="el-icon-place" caracter="&#xe800;" item="Encontrados" @click.native="goTo('home/found')" :isActive="found"></ps-item-menu>
      <!-- <ps-item-menu icono="el-icon-place" caracter="&#xe800;" item="Búsquedas" @click.native="goTo('home/searches')" :isActive="searches"></ps-item-menu> -->
      <ps-item-menu icono="el-icon-setting" caracter="&#xe802;" item="Configuración" @click.native="goTo('home/configuration')" :isActive="configuration"></ps-item-menu>
    </aside>
    <section id="body">
      <router-view :key="$route.fullPath" />
    </section>
  </main>
</template>

<script>
import PsItemMenu from '@/components/PsItemMenu.vue'

export default {
  name: 'PsMain',
  data () {
    return {
      sinisters: true,
      configuration: false,
      found: false,
      searches: false,
    }
  },
  components: { PsItemMenu },
  methods: {
    goTo(page) {
      if(page == 'home/sinisters') {
        this.configuration = false
        this.sinisters = true
        this.found = false
        this.searches = false
      } else if ( page == 'home/found') {
        this.sinisters = false
        this.configuration = false
        this.found = true
        this.searches = false
      // } else if ( page == 'home/searches') {
      //   this.sinisters = false
      //   this.configuration = false
      //   this.found = false
      //   this.searches = true
      } else if ( page == 'home/configuration') {
        this.sinisters = false
        this.configuration = true
        this.found = false
        this.searches = false
      }
      this.$router.push(`/${page}`)
    }
  }
}
</script>

<style scoped>

  /* MAIN */

  main {
    border-top: 1px solid #eee;  
    /* flex-grow: 30; */
    display: flex;
  }

  /* ASIDE */

  aside {
    min-width: 220px;
    border-right: 1px solid #ddd;
    box-shadow: 1px 3px 4px #eee;
    background-color: #fff; display: flex;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  #body {
    width: calc(100% - 220px);
  }
</style>