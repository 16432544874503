export const found = {
  chasisNumber: '',
  city: '',
  country: '',
  createdAt: '',
  createdBy: null,
  datetime: '',
  domain: '',
  id: null,
  imageUrl: '',
  latitude: '',
  longitude: '',
  mark: '',
  model: '',
  number: '',
  recovererId: null,
  region: '',
  status: '',
  street: '',
  updatedAt: '',
  updatedBy: null,
  vehicleId: null,
}