import { vehicleType } from './vehicleType_model';
import { model } from '../model/model_model';
import { color } from '../color/color_model';
import { mark } from '../mark/mark_model';

export const vehicle = {
  chasisNumber: '',
  Color: structuredClone(color),
  colorId: null,
  createdAt: null,
  createdBy: null,
  description: '',
  domain: '',
  id: null,
  imageDomain: null,
  Model: structuredClone(model),
  markId: null,
  Mark: structuredClone(mark),
  markFreeText: '',
  modelFreeText: '',
  modelId: null,
  origin: null,
  Sinister: [],
  updatedAt: null,
  updatedBy: null,
  VehicleType: structuredClone(vehicleType),
  vehicleTypeId: null,
  year: new Date().getFullYear(),
}