import { pagination_params } from "../../shared/utils/pagination_params";

export const getParamsUrl = (params) => {
  if(!params) return '';
  let path = '';
  path = pagination_params(params);
  
  params.id && (path = `${path != '' ? path + '&' : ''}id=${params.id}`);
  
  return `?${path}`;
}