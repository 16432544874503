<template>
  <div class="psFormAbm">
    <div class="fields">
      <slot>
        <input type="text" disabled>
      </slot>
    </div>
    <div class="buttons">
      <ps-button icon="icon-checkmark" caracter="&#xe80d;" text="Guardar" type="mono" @click.native="save" :disabled="disabledButton"></ps-button>
      <ps-button icon="icon-cross" caracter="&#xe80e;" text="Cancelar" type="mono" @click.native="cancel" :disabled="disabledButton"></ps-button>
    </div>
  </div>
</template>

<script>
import PsButton from '@/components/PsButton.vue'

export default {
  name: 'PsFormAbm',
  components: { PsButton },
  props: {
    disabledButton: Boolean
  },
  methods: {
    save () {
      this.$emit('save')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
  .psFormAbm { 
    border-top: 1px solid #3777f133; 
    border-bottom: 1px solid #3777f133; 
    padding: 15px 20px;
  }

  .fields {
    display: flex;
    flex-wrap: wrap;
  }
  .buttons { display: flex; margin-top: 16px; min-height: 40px;}
</style>