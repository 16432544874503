<template>
  <div class="psVerifyDomain">
    <div class="column flex-row-align-left size-m">
      <label>Dominio</label>
      <div class="row">
        <el-input placeholder="Dominio" v-model="domain" size="l" @keypress.prevent.native.enter="verifyDomain"></el-input>
        <el-button 
          @click="verifyDomain" 
          type="success" 
          circle icon="el-icon-search" 
          style="width: 42px; margin-left: 10px"
          :disabled="!domain || domain == ''"
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'PsVerifyDomain',
  data() {
    return {
      domain: ''
    }
  },
  computed: {
    ...mapState('vehicles', ['vehicles']),
  },
  methods: {
    ...mapMutations('vehicles', ['VEHICLES_VEHICLES_EMPTY', 'VEHICLES_SET_PAGE', 'VEHICLES_VEHICLE', 'VEHICLES_EDIT_MODE', 'VEHICLES_VEHICLE_PROPERTIES', 'VEHICLES_VEHICLE_EMPTY']),
    ...mapActions('vehicles', ['searchVehicleByDomain', 'selectVehicles', 'selectVehicle']),
    async verifyDomain() {
      if(this.domain != '') {
        this.VEHICLES_VEHICLES_EMPTY();
        this.VEHICLES_SET_PAGE(1);
        await this.selectVehicles({domain: this.domain});
        if(this.vehicles.length > 0) {
          this.selectVehicle(this.vehicles[0].id);
        } else {
          this.VEHICLES_VEHICLE_EMPTY();
          this.VEHICLES_VEHICLE_PROPERTIES({ property: 'domain', value: this.domain } );
          this.VEHICLES_EDIT_MODE(true);
        }

      }
    }
    
  }
}
</script>

<style scoped>
  label { font-size: 18px; margin-bottom: 12px; }
  .psVerifyDomain { padding-bottom: 30px;}
</style>