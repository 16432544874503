import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from '@/services/api'
import ElementUI from 'element-ui';
import './assets/styles/element-variables.scss'
import locale from 'element-ui/lib/locale/lang/es'

Vue.config.productionTip = false

Vue.use(ElementUI, { size: 'small', locale });

Vue.filter('formatearFechaDDMMYYYY', (value) => {
  let fecha = new Date(value)
  let dia = fecha.getDate()
  let mes = fecha.getMonth() + 1
  let anio = fecha.getFullYear()

  if (dia < 10) dia = '0' + dia
  if (mes < 10) mes = '0' + mes

  return !dia ? '' : dia.toString() + '/' + mes.toString() + '/' + anio.toString()
})

Vue.filter('convertDDMMYYYYHHMM', (value) => {
  if(!value) return value;
  let date = new Date(value)
  return `${date.toLocaleDateString('es-ar')} ${date.toLocaleTimeString('es-ar')}`;
})

api.interceptors.request.use(
  config => {
    if(localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user) {
        config.headers['token'] = user.token
      }
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  err => {
    if (err.response && err.response.status == 401) {
      router.replace('/')
      return Promise.reject(err);
    }
    return Promise.reject(err)
  }
)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
