<template>
  <div class="psUpload row">

      <img v-if="!!image.imageName" :src="fullPathImage" class="itemImg" @mouseenter="editImage(true)" @mouseleave="editImage(false)" :width="width" :height="height">
      
      <div class="botonesImagen" v-show="editarImagenActivo" @mouseover="editImage(true)">
        <el-button icon="el-icon-crop" size="mini" circle class="btnCropImage" @click.stop="cropFile"></el-button>
        <el-button icon="el-icon-delete" size="mini" circle class="btnDeleteImage" @click.stop="deleteFile"></el-button>
      </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsImageItem',
  data () {
    return {      
      editarImagenActivo: false,
      imgquality: 0.4
    }
  },
  props: { 
    image: Object, 
    index: Number,
    width: { 
      type: String, 
      default: null
    },
    height: { 
      type: String, 
      default: null
    },
  },
  computed: {
    ...mapState('images', ['crop', 'numberImageCrop']),
    ...mapState('sinisters', ['sinister', 'crop', 'numberImageCrop']),
    ...mapState('vehicles', ['vehicle']),
    fullPathImage() {
      return `${process.env.VUE_APP_URL_IMAGES_VEHICLES}/${this.image.imageName}`
    }
  },
  methods: {
    ...mapMutations('images', ['IMAGES_CROP', 'IMAGES_DESTINATION', 'IMAGES_NAME_IMAGE']),
    ...mapActions('images', ['uploadImage']),
    editImage (booleano) {
      this.editarImagenActivo = booleano
    },
    deleteFile () {
      this.$emit('deleteFile', this.index);
    },
    cropFile () {
      this.editarImagenActivo = false
      this.IMAGES_DESTINATION({
        type: 'sinister',
        property: this.index
      });
      this.IMAGES_NAME_IMAGE(this.image.imageName);
      this.IMAGES_CROP()
    }
  },
  mounted() {
    let images = document.querySelectorAll('.imagesSinister .el-upload-dragger')
    
    if( this.width && this.height ) {
      images.forEach( i => {
        i.style.width = this.width;
        i.style.height = this.height;
      })
    }  
  }
}
</script>

<style scoped>
  .psUpload { justify-content: flex-start; margin-top: 30px; margin-right: 20px; position: relative;}

  .itemImg {
    display: block;
  }

  .botonesImagen {
    position: absolute;
    bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 1000;
  }

  .btnEditImage, .btnDeleteImage, .btnCropImage { 
    background: #ffffff66;
    border: none;
    color: black
  }


</style>