import api from '@/services/api.js'
import { getParamsUrl } from './province_utils';
import { province } from './province_model';

const state = {
  provinces: [],
  province: {},
  fields: [
    { name: 'id', label: 'Codigo', width: 120 },
    { name: 'province', label: 'Descripcion', width: 300 },
    { name: 'active', label: 'Activo', width: 100 },
  ],
  page: 1,
  filters: {},
  editMode: false
}

const mutations = {
  PROVINCES_PROVINCES(state, provinces) { state.provinces = provinces },
  PROVINCES_PROVINCE(state, province) { state.province = structuredClone(province) },
  PROVINCES_PROVINCE_PROPERTIES(state, payload) { state.province[payload.property] = payload.value },
  PROVINCES_PROVINCE_EMPTY(state) { state.province = structuredClone(province) },
  PROVINCES_PROVINCES_EMPTY(state) { state.provinces = [] }, 
  PROVINCES_PAGE(state, page) { state.page = page },
  PROVINCES_FILTERS(state, filters) { state.filters = filters },
  PROVINCES_EDIT_MODE(state, editMode) { state.editMode = editMode }
}

const actions = {
  async selectProvinces({ commit }, params ) {
    try {
      let result = await api.get(`/province${getParamsUrl({...state.filters, ...params, page: state.page })}`)
      commit('PROVINCES_PROVINCES', result.data)
    } catch (err) { console.log(err) }
  },
  async updateProvince({ state, dispatch }) {
    const result = await api.patch(`/province/${state.province.id}`, state.province);
    if (result && result.data) {
      dispatch('selectProvinces')
    }
  },
  async createProvince({ state, dispatch }) {
    const result = await api.post(`/province`, state.province)
    if (result && result.data) {
      dispatch('selectProvinces')
    }
  }
}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }