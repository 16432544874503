<template>
  <div class="psSinisterVehicle">
    <div class="columnVehicle" v-if="vehicle.id">
      <div class="label" style="font-size: 14px; ">Vehículo</div>
      <div class="vehicleDescription">{{`${vehicle.Mark && vehicle.Mark.mark ? vehicle.Mark.mark : ''} ${vehicle.Model && vehicle.Model.model ? vehicle.Model.model : vehicle.modelFreeText}`}}</div>
    </div>  
    <div class="row">
      <ps-dato label="Nro Chasis" :value="vehicle.chasisNumber" size="size-mm"></ps-dato>          
      <ps-dato label="Tipo de vehículo" :value="vehicle.VehicleType.vehicleType" size="size-mm"></ps-dato>          
      <ps-dato label="Color" :value="vehicle.Color.color" size="size-mm"></ps-dato>          
      <ps-dato label="Año" :value="String(vehicle.year ? vehicle.year : '')" size="size-ss"></ps-dato>          
    </div>
    
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PsDato from '@/components/PsDato.vue';
import { vehicle } from '../../vehicles/vehicle_model';

export default {  
  computed: {
    ...mapState('vehicles', [ 'vehicle' ]),
  },
  components: { PsDato },
}
</script>

<style scoped>
  .columnVehicle { display: flex; flex-direction: column; align-items: flex-start; margin: 10px 0 25px; }
</style>