export const user = {
  id: null,
  user: '',
  password: null,
  repeatPassword: null,
  active: 1,
  createdAt: '',
  updatedAt: '',
  createdBy: null,
  updatedBy: null
}