<template>
  <div class="psImageDomain" @click.prevent="openDrawerSelectTemplate">
    <img v-if="!!nameImage" :src="nameImage" class="avatar" width="100%" height="100%">
    <i v-else class="el-icon-plus avatar-uploader-icon" :style="`width: ${width}; height: ${height}; line-height: ${height}; `"></i>    
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'PsImageDomain',
  data () {
    return {      
      editarImagenActivo: false,     
    }
  },
  props: { 
    nameImage: String,
    width: { 
      type: String, 
      default: null
    },
    height: { 
      type: String, 
      default: null
    },
  }, 
  computed: {
    ...mapState('images', ['crop', 'numberImageCrop']),
    ...mapState('vehicles', ['vehicle']),
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SELECT_TEMPLATE']),
    ...mapMutations('vehicles', ['VEHICLES_EDIT_MODE']),
    openDrawerSelectTemplate() {
      this.VEHICLES_EDIT_MODE(true);  
      this.SET_DRAWER_SELECT_TEMPLATE(true);
    }
  },
}
</script>

<style scoped>
  .psImageDomain {     
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 300px; 
    height: 169px;
  }

  .avatar-uploader-icon {
    line-height: 198px;
    font-size: 35px;
  }
</style>