<template>
  <div class="psItemMenu" :class="{isActive: isActive}">
    <span><i class="demo-icon" :class="icono" v-html="caracter" ></i> {{item}}</span>
  </div>
</template>

<script>
export default {
  name: 'PsItemMenu',
  props: {
    icono: String,
    caracter: String,
    item: String,
    isActive: Boolean
  },
}
</script>

<style scoped>
  .psItemMenu {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 30px;
  }

  .psItemMenu > span > i { font-size: 1.15rem; margin-right: 15px;}
  .psItemMenu > span { font-size: 1.15rem; }

  .psItemMenu:hover, .isActive {
    background-color: #F706A166;
    color: white;
    cursor: pointer;
  }



</style>