<template>
  <div class="psLocalities">
    <h2>Localidades</h2>
    <ps-bar-buttons>
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="back"></ps-button>
      <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew"></ps-button>      
    </ps-bar-buttons>
    <ps-form-abm @save="save" @cancel="cancel" :disabledButton="disabledButton">
      <div class="column flex-row-align-left">
        <label>Código</label>
        <el-input placeholder="Código" disabled class="size-ss disabled" :value="locality.id"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label for="code">Provincia</label>
        <el-select 
          id="marks" 
          :disabled="!editMode" 
          :class="[{disabled: !editMode}]" 
          :value="locality.Province.province"
          placeholder="Seleccionar una provincia"
          value-key="provincia"
          @change="changeProperty($event, 'Province')">
          <el-option v-for="province in listProvinces" :value="province" :key="province.id" :label="province.province"></el-option>
        </el-select>
      </div>
      <div class="column flex-row-align-left">
        <label>Descripción</label>
        <el-input placeholder="Localidad" class="size-l" :value="locality.locality" :disabled="!editMode"  @input="changeProperty($event, 'locality')" :class="[ {disabled: !editMode}]"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label for="active">Activo</label>
        <el-switch 
          :value="locality.active == 1 ? true : false"
          :disabled="!editMode" 
          class="check" 
          :class="[{disabled: !editMode}]" 
          @change="changeProperty(null, 'active')"
        ></el-switch>
        
      </div>
    </ps-form-abm>
    <div class="sectionTable">
      <el-table
        :data="localities.filter(l => l.id > 0)"
        highlight-current-row
        @current-change="selectRow"
        empty-text="Sin localidades"
        height="95%">
        <el-table-column v-for="(item, index) in fields" :key="index" :width="item.width" :prop="item.name" :label="item.label">
          <template slot-scope="scope">
            <el-tag  v-if="item.name == 'active'"
              :type="scope.row.active == 1 ? 'success' : 'danger'"
              disable-transitions>{{scope.row.active == 1 ? 'Activo' : 'Inactivo'}}</el-tag>
            <span v-else>{{scope.row[item.name]}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>  
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'
import PsFormAbm from '@/components/PsFormAbm.vue'
import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  name: 'PsLocalities',
  components: { PsBarButtons, PsButton, PsFormAbm },
  computed: {
    ...mapState('localities', ['locality', 'localities', 'fields', 'editMode']),
    ...mapState('provinces', ['provinces']),
    disabledButton () {
      return !(this.locality.locality != '' && this.locality.Province.id > 0)
    },
    listProvinces () {
      if(this.locality.id == null) {
        return this.provinces.filter( p => p.active == 1 && p.id > 0)
      }
      return this.provinces.filter( p => p.id > 0)
    }
  },
  methods: {
    ...mapMutations('localities', ['LOCALITIES_LOCALITY', 'LOCALITIES_LOCALITY_PROPERTIES', 'LOCALITIES_LOCALITY_EMPTY', 'LOCALITIES_LOCALITIES_EMPTY', 'LOCALITIES_EDIT_MODE', 'LOCALITIES_PAGE']),
    ...mapMutations('provinces', ['PROVINCES_PAGE', 'PROVINCES_PROVINCES_EMPTY']),
    ...mapActions('localities', ['selectLocalities', 'createLocality', 'updateLocality']),
    ...mapActions('provinces', ['selectProvinces']),
    psNew () {
      this.LOCALITIES_LOCALITY_EMPTY();
      this.LOCALITIES_EDIT_MODE(true);
    },
    selectRow(row) {
      if(row) {
        delete row.province;
        this.LOCALITIES_LOCALITY(row);
        this.LOCALITIES_EDIT_MODE(true);
      }
    },
    changeProperty (value, property) {   
      if( property == 'active') {
        this.LOCALITIES_LOCALITY_PROPERTIES({property, value: !this.locality.active })
      } else {
        this.LOCALITIES_LOCALITY_PROPERTIES({property, value });
      }
    },
    back () {
      this.$router.go(-1)
      this.LOCALITIES_LOCALITY_EMPTY();
      this.LOCALITIES_EDIT_MODE(false);
    },
    save () {
      if(this.locality.id) {
        this.updateLocality()
        .then( () => this.$notify( { title: 'Mensaje de confirmación', message: 'Localidad actualizada', type: 'success'}))
        .then(() => {
          this.LOCALITIES_LOCALITY_EMPTY();
          this.LOCALITIES_EDIT_MODE(false);
        })
        .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe la localidad ${this.locality.locality}`
            } else {
              message = 'Error al crear o modificar una localidad';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
      } else {
        this.createLocality()
          .then( () => this.$notify( { title: 'Mensaje de confirmación', message: 'Localidad creada', type: 'success'}))
          .then(() => {
            this.LOCALITIES_LOCALITY_EMPTY();
            this.LOCALITIES_EDIT_MODE(false);
          })
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe la localidad ${this.locality.locality}`
            } else {
              message = 'Error al crear o modificar una localidad';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
      }
      
    },
    cancel () {
      this.LOCALITIES_LOCALITY_EMPTY();
      this.LOCALITIES_EDIT_MODE(false);
    },
  },
  async created () {
    this.LOCALITIES_PAGE(0);
    this.LOCALITIES_LOCALITIES_EMPTY();
    this.LOCALITIES_LOCALITY_EMPTY();
    this.PROVINCES_PAGE(0);
    this.PROVINCES_PROVINCES_EMPTY();
    await this.selectLocalities()
    await this.selectProvinces()
  }
}
</script>

<style scoped>

  .psLocalities { padding: 0 20px; height: 100%; display: flex; flex-direction: column; }

  h2 {
    margin: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    
  }

  .psBarButtons{ min-height: 40px; padding-left: 20px}
  .psFormAbm{ height: 25%; min-height: 160px;}
  .psTableAbm{ height: 53%;}

  .column { margin-right: 20px; }
  .check { margin: 13px 0}
  .sectionTable { flex-grow: 1; }
  
</style>