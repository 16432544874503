<template>
  <div class="psModels">
    <h2>Modelos</h2>
    <ps-bar-buttons>
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="back"></ps-button>
      <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew"></ps-button>      
    </ps-bar-buttons>
    <ps-form-abm @save="save" @cancel="cancel" :disabledButton="disabledButton">
      <div class="column flex-row-align-left">
        <label>Código</label>
        <el-input placeholder="Código" disabled class="size-ss disabled" :value="model.id"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label for="code">Marca</label>
        <el-select 
          id="marks" 
          :disabled="!editMode" 
          :class="[{disabled: !editMode}]" 
          :value="model.Mark.mark"
          placeholder="Seleccionar una marca"
          value-key="marca"
          @change="changeProperty($event, 'Mark')"
          filterable>
          <el-option v-for="mark in listMarks" :value="mark" :key="mark.id" :label="mark.mark"></el-option>
        </el-select>
      </div>
      <div class="column flex-row-align-left">
        <label for="model">Descripción</label>
        <el-input placeholder="Modelo del vehículo" class="size-l" :value="model.model" :disabled="!editMode"  @input="changeProperty($event, 'model')" :class="[ {disabled: !editMode}]"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label for="active">Activo</label>
        <el-switch 
          :value="model.active == 1 ? true : false" 
          :disabled="!editMode"
          class="check" 
          :class="[{disabled: !editMode}]" 
          @change="changeProperty(null, 'active')"
        ></el-switch>        
      </div>
    </ps-form-abm>
    <div class="sectionTable">
      <el-table
        :data="models.filter(m => m.id > 0)"
        highlight-current-row
        @current-change="selectRow"
        empty-text="Sin modelos"
        height="95%">
        <el-table-column v-for="(item, index) in fields" :key="index" :width="item.width" :prop="item.name" :label="item.label">
          <template slot-scope="scope">
            <el-tag  v-if="item.name == 'active'"
              :type="scope.row.active == 1 ? 'success' : 'danger'"
              disable-transitions>{{scope.row.active == 1 ? 'Activo' : 'Inactivo'}}</el-tag>
            <span v-else>{{scope.row[item.name]}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>  
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'
import PsFormAbm from '@/components/PsFormAbm.vue'
import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  name: 'PsModels',
  components: { PsBarButtons, PsButton, PsFormAbm },
  computed: {
    ...mapState('models', ['model', 'models', 'fields', 'editMode']),
    ...mapState('marks', ['marks']),
    disabledButton () {
      return !(this.model != null && this.model.model != '' && this.model.Mark != null && this.model.Mark.mark != '')
    },
    markSelected () {              
        return this.model.Mark == null || this.model.Mark.mark == '' ? 'Selecciona una marca' : JSON.stringify(this.marks.find( i => i.id == this.model.idMark )) 
      
    },
    listMarks () {
      if(this.model.id == null) {
        return this.marks.filter( m => m.active == 1 && m.id > 0);
      }
      return this.marks.filter( m => m.id > 0);
    }
  },
  methods: {
    ...mapMutations('models', ['MODELS_MODEL', 'MODELS_MODEL_PROPERTIES', 'MODELS_MODELS_EMPTY', 'MODELS_MODEL_EMPTY', 'MODELS_PAGE', 'MODELS_EDIT_MODE']),
    ...mapMutations('marks', ['MARKS_MARKS_EMPTY', 'MARKS_PAGE']),
    ...mapActions('models', ['selectModels', 'createModel', 'updateModel']),
    ...mapActions('marks', ['selectMarks']),
    psNew () {
      this.MODELS_EDIT_MODE(true);
      this.MODELS_MODEL_EMPTY();
    },
    selectRow(row) {
      delete row.mark;
      this.MODELS_MODEL(structuredClone(row));
      this.MODELS_EDIT_MODE(true);
    },
    changeProperty (value, property) {   
      if( property == 'active') {
        this.MODELS_MODEL_PROPERTIES({property, value: !this.model.active })
      } else {
        this.MODELS_MODEL_PROPERTIES({property, value: value })
      }
    },
    back () {
      this.$router.go(-1)
      this.MODELS_MODEL_EMPTY();
      this.MARKS_EDIT_MODE(false);
    },
    save () {
      if(this.model.id) {
        this.updateModel()
          .then( () => this.$notify( { title: 'Mensaje de confirmación', message: 'Modelo actualizado', type: 'success'}))
          .then(() => this.MODELS_MODEL_EMPTY())
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe el modelo ${this.model.model}`
            } else {
              message = 'Error al crear o modificar un modelo';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })     
      } else {
        this.createModel()
          .then( () => this.$notify( { title: 'Mensaje de confirmación', message: 'Modelo creado', type: 'success'}))
          .then(() => {
            this.MODELS_MODEL_EMPTY();
            this.MODELS_EDIT_MODE(false);
          })
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe el modelo ${this.model.model}`
            } else {
              message = 'Error al crear o modificar un modelo';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })          
      }
      
    },
    cancel () {
      this.MODELS_MODEL_EMPTY();
      this.MODELS_EDIT_MODE(false);
    },
  },
  async created () {
    this.MODELS_PAGE(0);
    this.MODELS_MODELS_EMPTY();
    this.MODELS_MODEL_EMPTY();
    this.MARKS_PAGE(0);
    this.MARKS_MARKS_EMPTY();
    await this.selectModels();
    await this.selectMarks();
  }
}
</script>

<style scoped>

  .psModels { padding: 0 20px; height: 100%; display: flex; flex-direction: column; }

  h2 {
    margin: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    
  }

  .psBarButtons{ min-height: 40px; padding-left: 20px}
  .psFormAbm{ height: 25%; min-height: 160px;}
  .psTableAbm{ height: 53%;}

  .column { margin-right: 20px; }
  .check { margin: 13px 0}
  .sectionTable { flex-grow: 1; }
  
</style>