<template>
  <div class="psUpload row">
    <el-upload
      class="avatar-upload"
      drag
      action=""
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :multiple="false"
      :show-file-list="false"
      :before-upload="beforeavatarupload"
      :http-request="addFile"
      >
      
      <i class="el-icon-plus avatar-uploader-icon" :style="`width: ${width}; height: ${height}; line-height: ${height}; `"></i>   
    </el-upload>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsUpload',
  data () {
    return {      
      editarImagenActivo: false,
      imgquality: 0.4
    }
  },
  props: { 
    width: { 
      type: String, 
      default: null
    },
    height: { 
      type: String, 
      default: null
    },
  },
  computed: {
    ...mapState('images', ['crop', 'numberImageCrop']),
    ...mapState('sinisters', ['sinister', 'crop', 'numberImageCrop']),
  },
  methods: {
    ...mapMutations('images', ['IMAGES_CROP', 'IMAGES_DESTINATION', 'IMAGES_NAME_IMAGE']),
    ...mapMutations('sinisters', ['SINISTERS_SINISTER']),
    ...mapActions('images', ['uploadImage']),
    handlePreview (file) {
      console.log(file)
    },
    handleRemove (file) {
      console.log(file)
    },
    dataUriToBlob (datauri, type) {
      var binary = atob (datauri.split (",") [1]);
      var array = [];
      for (var i = 0; i < binary.length; i ++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {type:type});
    },
    beforeavatarupload (file) {
      const _this=this
      return new Promise (resolve => {
        const reader = new FileReader()
        const image = new Image ()
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const width = image.width * _this.imgquality
          const height = image.height * _this.imgquality
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          const dataurl = canvas.toDataURL(file.type);
          const blobdata = _this.dataUriToBlob (dataurl, file.type);
          resolve(blobdata)
        }
        reader.onload = (e =>{image.src = e.target.result;});
        reader.readAsDataURL(file);
      })
    },
    async addFile (file) {
      const filename = await this.uploadImage({file, type: 'sinister' });
      if(filename) {
        const sinister = structuredClone(this.sinister);
        sinister.SinisterImage.push(filename);
        this.SINISTERS_SINISTER(sinister);
      }
    },
  },
  mounted() {
    let images = document.querySelectorAll('.imagesSinister .el-upload-dragger')
    
    if( this.width && this.height ) {
      images.forEach( i => {
        i.style.width = this.width;
        i.style.height = this.height;
      })
    }  
  }
}
</script>

<style scoped>
  .psUpload { justify-content: flex-start; margin-top: 30px; margin-right: 20px;}

  .avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    border-radius: 89px;
    overflow: hidden;
    display: flex;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    /* width: 100%;
    height: 100%; */
    display: block;
  }


</style>