<template>
  <div class="psUsers">
    <h2>Usuarios</h2>
    <ps-bar-buttons>
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="back"></ps-button>
      <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew"></ps-button>      
    </ps-bar-buttons>
    <ps-form-abm @save="save" @cancel="cancel" :disabledButton="disabledButton">
      <div class="column">
        <div class="row">
          <div class="column flex-row-align-left">
            <label>Código</label>
            <el-input placeholder="Código" disabled class="size-ss disabled" :value="user.id"></el-input>
          </div>      
          <div class="column flex-row-align-left">
            <label>Usuario</label>
            <el-input placeholder="Usuario" class="size-m" :value="user.user" :disabled="!editMode"  @input="changeProperty($event, 'user')" :class="[ {disabled: !editMode}]"></el-input>
          </div>
          <div class="column flex-row-align-left">
            <label for="active">Activo</label>
            <el-switch 
              :value="user.active == 1 ? true : false" 
              :disabled="!editMode"
              class="check" 
              :class="[{disabled: !editMode}]" 
              @change="changeProperty(null, 'active')"
            ></el-switch>
          </div>
        </div>
        <el-button v-if="user.id > 0 && changePassword == false " type="success" class="size-m" @click="USERS_CHANGE_PASSWORD(!changePassword)">Cambiar password</el-button>
        <div class="row" v-else-if="changePassword == true">          
          <div class="column flex-row-align-left">
            <label>Password</label>
            <el-input placeholder="Password" type="password" class="size-m" :value="user.password" :disabled="!editMode"  @input="changeProperty($event, 'password')" :class="[ {disabled: !editMode}]"></el-input>
          </div>
          <div class="column flex-row-align-left">
            <label>Repetir password</label>
            <el-input placeholder="Repetir password" type="password" class="size-m" :value="user.repeatPassword" :disabled="!editMode"  @input="changeProperty($event, 'repeatPassword')" :class="[ {disabled: !editMode}]"></el-input>
          </div>
        </div>
      </div>
    </ps-form-abm>

    <div class="sectionTable">
      <el-table
        
        :data="users"
        highlight-current-row
        @current-change="selectRow"
        empty-text="Sin usuarios"
        height="95%">
        <el-table-column v-for="(item, index) in fields" :key="index" width="180" :prop="item.name" :label="item.label">
          <template slot-scope="scope">
            <el-tag  v-if="item.name == 'active'"
              :type="scope.row.active == 1 ? 'success' : 'danger'"
              disable-transitions>{{scope.row.active == 1 ? 'Activo' : 'Inactivo'}}</el-tag>
            <span v-else>{{scope.row[item.name]}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'
import PsFormAbm from '@/components/PsFormAbm.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import { user } from './user_model';

export default {
  name: 'PsUsers',
  components: { PsBarButtons, PsButton, PsFormAbm },
  computed: {
    ...mapState('users', ['user', 'users', 'fields', 'editMode', 'changePassword']),
    disabledButton () {
      return !this.user.user
    }
  },
  methods: {
    ...mapMutations('users', ['USERS_USER', 'USERS_USER_PROPERTIES', 'USERS_USER_EMPTY', 'USERS_USERS_EMPTY', 'USERS_PAGE', 'USERS_EDIT_MODE', 'USERS_CHANGE_PASSWORD']),
    ...mapActions('users', ['selectUsers', 'createUser', 'updateUser']),
    psNew () {
      this.USERS_USER_EMPTY();
      this.USERS_EDIT_MODE(true);
      this.USERS_CHANGE_PASSWORD(true);
    },
    selectRow(row) {
      if(row) {
        this.USERS_USER({...user, ...row});
        this.USERS_EDIT_MODE(true);
      }
    },
    changeProperty (value, property) { 
      if( property == 'active') {
        this.USERS_USER_PROPERTIES({property, value: !this.user.active })
      } else {
        this.USERS_USER_PROPERTIES({property, value: value })
      }
    },
    back () {
      this.$router.go(-1)
      this.USERS_USER_EMPTY();
      this.USERS_EDIT_MODE(false);
    },
    save () {
      if(this.changePassword == true) {
        if(!this.user.password || !this.user.repeatPassword || this.user.password != this.user.repeatPassword) {
          this.$notify( { title: 'Mensaje de error', message: 'Password y repetir password deben ser iguales', type: 'warning'});
          return;
        }  
      }
      if(this.user.id) {
        this.updateUser()
          .then( () => {
            this.USERS_USER_EMPTY();
            this.USERS_EDIT_MODE(false);
            this.USERS_CHANGE_PASSWORD(false);
            this.$notify( { title: 'Mensaje de confirmación', message: 'Usuario actualizado', type: 'success'})
          })
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe el usuario ${this.user.user}`
            } else {
              message = 'Error al crear o modificar un usuario';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
      } else {
        this.createUser()
          .then( () => {
            this.USERS_USER_EMPTY();
            this.USERS_EDIT_MODE(false);
            this.USERS_CHANGE_PASSWORD(false);
            this.$notify( { title: 'Mensaje de confirmación', message: 'Usuario creado', type: 'success'})
          })
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe el usuario ${this.user.user}`
            } else {
              message = 'Error al crear o modificar un usuario';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
        }      
    },
    cancel () {
      this.USERS_USER_EMPTY();
      this.USERS_EDIT_MODE(false);
      this.USERS_CHANGE_PASSWORD(false);
    }
  },
  async created () {
    this.USERS_PAGE(0);
    this.USERS_USER_EMPTY();
    this.USERS_USERS_EMPTY();
    await this.selectUsers()
  }
}
</script>

<style scoped>

  .psUsers { padding: 0 20px; height: 100%; display: flex; flex-direction: column; }

  h2 {
    margin: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    
  }

  .psBarButtons{ min-height: 40px; padding-left: 20px}
  .psFormAbm{ height: 25%; min-height: 220px;}
  .psTableAbm{ height: 53%;}

  .column { margin-right: 20px; }

  .column { margin-right: 20px; }
  .check { margin: 13px 0}
  .sectionTable { flex-grow: 1; }
</style>