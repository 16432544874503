import { locality } from '../locality/locality_model';
import { company } from '../company/company_model';
import { province } from '../province/province_model';

export const sinister_image = {
  id: null,
  sinisterId: null,
  order: null,
  imageName: '',
}

export const sinister = {
  id: null,
  trackingCode: '',
  stealingDatetime: '',
  localityId: null,
  stealingZone: '',
  companyId: null,
  insuredForStealing: 0,
  hasTracking: 0,
  startedAt: '',
  clientName: '',
  clientSurname: '',
  clientTelephone: '',
  clientTelephone2: '',
  clientEmail: '',
  observations: '',
  vehicleId: null,
  status: 'Robado',
  post: 1,
  isCompany: 0,
  createdAt: '',
  createdBy: null,
  updatedAt: '',
  updatedBy: null,
  Company: structuredClone(company),
  Locality: structuredClone(locality),
  localityFreeText: '',
  provinceFreeText: '',
  provinceId: null,
  Province: structuredClone(province),
  Vehicle: null,
  street: '',
  postalCode: '',
  customerId: null,
  SinisterImage: [ structuredClone(sinister_image) ]
}
