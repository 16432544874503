import api from '@/services/api.js';
import { getParamsUrl } from './model_utils';
import { model } from './model_model';

const state = {
  models: [],
  model: {},
  fields: [
    { name: 'id', label: 'Codigo', width: 120 },
    { name: 'mark', label: 'Marca', width: 250 },
    { name: 'model', label: 'Modelo', width: 350 },
    { name: 'active', label: 'Activo', width: 100 },
  ],
  page: 1,
  filters: {},
  editMode: false
}

const mutations = {
  MODELS_MODELS(state, models) { state.models = models },
  MODELS_MODEL(state, model) { state.model = Object.assign({}, model) },
  MODELS_MODEL_PROPERTIES(state, payload) { state.model[payload.property] = payload.value },
  MODELS_MODEL_EMPTY(state) { state.model = structuredClone(model) },
  MODELS_MODELS_EMPTY(state) { state.model = [] },
  MODELS_MODEL_UNDEFINED(state) { state.model = {} },
  MODELS_PAGE(state, page) { state.page = page },
  MODELS_FILTERS(state, filters ) { state.filters = filters },
  MODELS_EDIT_MODE(state, editMode) { state.editMode = editMode }
}

const actions = {
  async selectModels({ commit }, params) {
    try {
      let result = await api.get(`/model${getParamsUrl({ ...state.filters, ...params, page: state.page })}`)
      commit('MODELS_MODELS', result.data)
    } catch (err) { console.log(err) }
  },
  async updateModel({ state, dispatch }) {
    const result = await api.patch(`/model/${state.model.id}`, state.model);
    if (result && result.data) {
      dispatch('selectModels')
    }
  },
  async createModel({ state, dispatch }) {
    const result = await api.post(`/model`, state.model)
    if (result && result.data) {
      dispatch('selectModels')
    }
  }
}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }