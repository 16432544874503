<template>
  <div class="cropModal">
    <div class="containerCrop">
      <div class="row right">
        <el-button circle icon="el-icon-close" @click=exit></el-button>
      </div>
      <div class="row firstLine">
        <div class="cropArea">
          <vue-cropper
            v-if="isMounted"
            ref="cropper"
            :src="fullPathImage"
            alt="Source image"
            :aspect-ratio="16 / 9"
          ></vue-cropper>
          <img v-else src='@/assets/images/placeholder.png' alt="Placeholder image">          
        </div>
        <div class="btnCrop">
          <el-button icon="el-icon-crop" type="info" plain @click="crop">Cortar</el-button>
        </div>
        <div class="previewArea" id="previewArea">
          <img v-if="cropImg" :src="cropImg" alt="Cropped image">
          <img v-else src='@/assets/images/placeholder.png' alt="Placeholder image">
        </div>
      </div>
      <div class="row secondLine">
        <div>
          <button id="back" @click="saveFile" :disabled="!cropImg" :class="{disabled: !cropImg}">Guardar foto cortada</button>          
        </div>        
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsCropModal',
  components: { VueCropper },
  data () {
    return {
      isMounted: false,
      cropImg: ''
    }
  },
  computed: {
    ...mapState('images', ['imageCrop', 'nameImage', 'destination']),
    ...mapState('sinisters', ['sinister']),
    fullPathImage() {
      return `${process.env.VUE_APP_URL_IMAGES_VEHICLES}/${this.nameImage}`
    }
  },
  methods: {
    ...mapMutations('images', ['IMAGES_CROP', 'IMAGES_DESTINATION', 'IMAGES_NAME_IMAGE']),
    ...mapMutations('sinisters', ['SINISTERS_SINISTER']),
    ...mapMutations('vehicles', ['VEHICLES_VEHICLE_PROPERTIES']),
    ...mapActions('images', ['uploadImage']),
    crop () {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();      
    },
    async saveFile () {
      let fileImage = this.base64toFile( this.cropImg, 'cropImage.jpg' );
      const filename = await this.uploadImage( { file: {file: fileImage }, type: this.destination.type, property: this.destination.property });
      
      if(this.destination.type == 'sinister') {
        const sinister = structuredClone(this.sinister);
        sinister.SinisterImage[this.destination.property].imageName = filename.imageName;
        this.SINISTERS_SINISTER(sinister);
      }
      if(this.destination.type == 'vehicle') {
        this.VEHICLES_VEHICLE_PROPERTIES({ property: this.destination.property, value: filename });
      }
      this.IMAGES_DESTINATION(null);
      this.IMAGES_CROP()
    },
    base64toFile( dataurl, filename ) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
    },
    exit () {
      this.IMAGES_CROP()      
    }

  },
  mounted () {
    setTimeout( () => this.isMounted = true, 600)
    let pa = document.querySelector('#previewArea')
    pa.style.height = pa.style.width * (16/9)
  }
}
</script>

<style scoped>
  .cropModal { 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000ee;
    display: flex;
    justify-content: center; align-items: center;
    z-index: 100;
  }

  .containerCrop { background-color: white; display: flex; flex-direction: column; justify-content: space-between;  width: 75%;  min-width: 1000px; min-height: 500px; padding: 20px}
  .firstLine { display: flex; justify-content: space-between; flex-grow: 3;}
  .secondLine { display: flex; justify-content: center; flex-grow: 2;  padding-top: 20px;}
  .cropArea { width: 40%; }
  .btnCrop { align-self: center;}
  .previewArea { width: 40%; background-color: #ddd; }
  .previewArea img, .cropArea img { width: 100%;}
  #back {
    padding: 15px 60px; 
    color: white;
    background-color: #F706A155;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.2s ease;
  }
  #back:hover { box-shadow: 0 0 16px #aaa;}
  .right { justify-content: flex-end; margin-bottom: 15px;}
  #back.disabled { background-color: #eee; border: 1px solid #ddd; cursor: not-allowed }

</style>