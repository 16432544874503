<template>
  <div class="psHome">
    <ps-header></ps-header>
    <ps-main></ps-main>
    <ps-crop-modal v-if="crop"></ps-crop-modal>
    <ps-drawer-select-template></ps-drawer-select-template>
  </div>
</template>

<script>
import PsHeader from '@/components/PsHeader.vue'
import PsMain from '@/components/PsMain.vue'
import PsCropModal from '@/components/PsCropModal.vue'
import PsDrawerSelectTemplate from '@/views/sinisters/widgets/PsDrawerSelectTemplate.vue';
import { mapState } from 'vuex'

export default {
  name: 'PsHome',
  components: { PsHeader, PsMain, PsCropModal, PsDrawerSelectTemplate },
  computed: {
    ...mapState('images', ['crop']),
  }
}
</script>

<style scoped>
  .psHome { height: 100%; display: flex; flex-direction: column;}
  .psHeader { height: 9%;}
  .psMain { height: 91%;}
</style>
