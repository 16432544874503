export const pagination_params = (params) => {
  if(!params) return '';
  let path = '';

  +params.page >= 0 && (path = `${path}${path != '' ? '&' : ''}page=${params.page}`);
  +params.limit >= 0 && (path = `${path}${path != '' ? '&' : ''}limit=${params.limit}`);
  +params.offset >= 0 && (path = `${path}${path != '' ? '&' : ''}offset=${params.offset}`);
  params.sortBy && (path = `${path}${path != '' ? '&' : ''}sortBy=${params.sortBy}`);
  params.sort && (path = `${path}${path != '' ? '&' : ''}sort=${params.sort}`);

  return path;
};