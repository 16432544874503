import api from '@/services/api.js';
import { getParamsUrl } from './Vehicles_utils';
import { vehicle } from './vehicle_model';
import { model } from '../model/model_model';

const state = {
  vehicle: {},
  vehicles: [],
  vehiclesTypes: [],
  errors: [],
  editMode: false,
  filters: {},
  page: 1,
}

const mutations = {
  VEHICLES_VEHICLES(state, vehicles) { state.vehicles.push(...vehicles)},
  VEHICLES_VEHICLES_TYPES(state, vehiclesTypes) { state.vehiclesTypes = vehiclesTypes },
  VEHICLES_VEHICLE(state, vehicle) { state.vehicle = Object.assign({}, vehicle) },
  VEHICLES_VEHICLE_PROPERTIES(state, payload) { state.vehicle[payload.property] = payload.value },
  VEHICLES_VEHICLE_EMPTY(state) { state.vehicle = structuredClone(vehicle) },
  VEHICLES_VEHICLE_UNDEFINED(state) { state.vehicle = {} },
  VEHICLES_ERROR(state, error) { state.errors.push(error) },
  VEHICLES_ERROR_EMPTY(state) { state.errors = [] },
  VEHICLES_EDIT_MODE(state, editMode) { state.editMode = editMode },
  VEHICLES_SET_PAGE(state, page) { state.page = page },
  VEHICLES_VEHICLES_EMPTY(state) { state.vehicles = [] }
}

const actions = {
  async selectVehiclesTypes({ commit }) {
    try {
      let result = await api.get('/vehicleType')
      commit('VEHICLES_VEHICLES_TYPES', result.data)
    } catch (err) { console.log(err) }
  },
  async selectVehicles({ commit }, params) {
    try {
      let result = await api.get(`/vehicle${getParamsUrl({...state.filters, ...params, page: state.page})}`);
      commit('VEHICLES_VEHICLES', result.data)
      commit('VEHICLES_SET_PAGE', state.page++ )
    } catch (err) { console.log(err); throw err.response.data.err.details; }
  },
  async selectVehicle({ commit }, id) {
    try {
      let result = await api.get(`/vehicle/${id}`);
      const vehicle = result.data;
      if(!vehicle.Model) {
        vehicle.Model = structuredClone(model);
      }
      if(!vehicle.Mark) {
        vehicle.Mark = structuredClone(mark);
      }
      commit('VEHICLES_VEHICLE', vehicle)
    } catch (err) { console.log(err); throw err.response.data.err.details; }
  },
  async searchVehicleByDomain({ commit, dispatch }, domain ) {
    try {
      let result = await api.get(`/vehicles/1/V.dominio/${domain}`);
      
      if( result.data.data.length > 0 ) {   
        await dispatch('sinisters/selectSinister', { vehicleId: result.data.data[0].id  }, { root: true } );
      } else {
        commit('VEHICLES_VEHICLE_EMPTY');
        commit('VEHICLES_VEHICLE_PROPERTIES', { property: 'dominio', value: domain } );
        commit('VEHICLES_EDIT_MODE', true)
      }
    } catch (err) { throw err.response.data.err.details; }
  },
  async updateVehicle({ state }) {
    try {
      let result = await api.patch(`/vehicle/${state.vehicle.id}`, state.vehicle)
      if (result.data) {
        return result.data;
      }
    } catch (err) { throw err.response.data.err.details; }
  },
  async updateImagenDominio({ state }, urlImage ) {
    try {
      await api.put(`/vehicles/image/${state.vehicle.id}`, { urlImage });
    } catch (err) { throw err.response.data.err.details; }
  },
  async deleteImagenDominio({ state } ) {
    try {
      await api.put(`/vehicles/deleteImage/${state.vehicle.id}`);
    } catch (err) { throw err.response.data.err.details; }
  },
  async createVehicle({ state }) {
    try {
      console.log('createVehicle')
      let result = await api.post('/vehicle', state.vehicle)
      console.log(result)
      if (result.data) {
        return result.data;
      }
    } catch (err) { 
      throw err.response.data
    }

  },
  async saveVehicle({ state, commit, dispatch }) {
    try {
      let v = state.vehicle

      if (!v.domain || v.domain == '') commit('VEHICLES_ERROR', 'El dominio es un dato requerido ')
      if (!v.Model || v.Model.id == 0) commit('VEHICLES_ERROR', 'El modelo es un dato requerido ')
      if (!v.Mark.id || v.Mark.id == 0) commit('VEHICLES_ERROR', 'La marca es un dato requerido ')
      if (!v.Color || v.Color.id == 0) commit('VEHICLES_ERROR', 'El color es un dato requerido ')
      if (!v.year || v.year == '') commit('VEHICLES_ERROR', 'El año es un dato requerido ')


      if (state.errors.length == 0) {
        if (state.vehicle.id) {
          return await dispatch('updateVehicle');
        } else {
          return await dispatch('createVehicle');
        }
      } else {
        throw 'error'
      }
    } catch (err) {
      throw { err, details: err }
    }
  },
}

const getters = {}

export default { state, mutations, actions, getters, namespaced: true }