<template>
  <div class="psSinisterVehicleEdit">
    
    <ps-form-abm @save="save" @cancel="cancel" :disabledButton="disabledButton">
      <div class="column">
        <div class="row">
          <div class="column flex-row-align-left">
            <label>Dominio</label>
            <el-input placeholder="Dominio" class="size-s" :value="vehicle.domain"
              @input="changeProperty($event, 'domain')"></el-input>
          </div>
          <div class="column flex-row-align-left size-mm">
            <label for="code">Marca</label>
            <el-select :value="vehicle.Mark.mark" placeholder="Seleccionar una marca" value-key="marca" filterable
              @change="changeProperty($event, 'Mark')" no-data-text="Sin marcas para mostrar">
              <el-option v-for="(mark, index) in listMarks" :key="index" :value="mark" :label="mark.mark"></el-option>
            </el-select>
          </div>
          <div class="column flex-row-align-left size-mm">
            <label for="code">Modelo</label>
            <el-select :value="vehicle.Model.model" placeholder="Seleccionar un modelo" value-key="modelo" filterable
              no-data-text="Sin modelos para mostrar" :disabled="vehicle.Mark.id == 0 ? true : false"
              @change="changeProperty($event, 'Model')">
              <el-option v-for="(model, index) in listModels" :key="index" :value="model" :label="model.model || ''">
              </el-option>
            </el-select>
          </div>
          <div class="column flex-row-align-left size-s">
            <label for="code">Modelo texto libre</label>
            <el-input placeholder="Modelo texto libre" class="size-s" :disabled="true" :value="vehicle.modelFreeText"
              @input="changeProperty($event, 'modelFreeText')"></el-input>
          </div>
        </div>
        
        <div class="row">
          <div class="column flex-row-align-left size-s">
            <label for="code">Año</label>
            <el-input-number placeholder="Año" :controls="false" class="size-ss" :value="vehicle.year"
              @input="changeProperty($event, 'year')" :min="1960" :max="maxYear" :precision="0"></el-input-number>
          </div>
          <div class="column flex-row-align-left">
            <label>Nro Chasis</label>
            <el-input placeholder="Nro chasis" class="size-m" :value="vehicle.chasisNumber"
              @input="changeProperty($event, 'chasisNumber')"></el-input>
          </div>          

          <div class="column flex-row-align-left size-mm">
            <label for="code">Tipo de vehículo</label>
            <el-select :value="vehicle.VehicleType.vehicleType" placeholder="Seleccionar un tipo de vehiculo" filterable
              value-key="tipoVehiculo" no-data-text="Sin tipos de vechiculos para mostrar"
              @change="changeProperty($event, 'VehicleType')">
              <el-option v-for="(typeVehicle, index) in listTypesVehicles" :key="index" :value="typeVehicle"
                :label="typeVehicle.vehicleType"></el-option>
            </el-select>
          </div>

          <div class="column flex-row-align-left size-mm">
            <label for="code">Color</label>
            <el-select :value="vehicle.Color.color" placeholder="Seleccionar un color" value-key="color" filterable
              no-data-text="Sin colores para mostrar" @change="changeProperty($event, 'Color')">
              <el-option v-for="(color, index) in listColors" :key="index" :value="color" :label="color.color">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </ps-form-abm>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import PsFormAbm from '@/components/PsFormAbm.vue';
import { model } from '../../model/model_model';

export default {
  name: 'PsSinisterVehicleEdit',
  components: { PsFormAbm },
  computed: {
    ...mapState('vehicles', [ 'vehicle', 'vehiclesTypes', 'errors' ]),
    ...mapState('provinces', ['provinces']),
    ...mapState('localities', ['localities']),
    ...mapState('marks', ['marks']),
    ...mapState('models', ['models']),
    ...mapState('colors', ['colors']),
    disabledButton() { return false; },
    maxYear() {
      let date = new Date()
      return date.getFullYear()
    },
    listColors() {
      return this.colors.filter(c => c.active == 1)
    },
    listMarks() {
      return this.marks.filter(m => m.active == 1)
    },
    listModels() {
      return this.models.filter(m => m.active == 1);
    },
    listTypesVehicles() {
      return this.vehiclesTypes.filter(tv => tv.active == 1);
    },
  },
  methods: {
    ...mapMutations('vehicles', [ 'VEHICLES_VEHICLE_UNDEFINED', 'VEHICLES_ERROR_EMPTY', 'VEHICLES_VEHICLE_PROPERTIES', 'VEHICLES_EDIT_MODE' ]),
    ...mapMutations('models', ['MODELS_PAGE', 'MODELS_MODEL_EMPTY']),
    ...mapActions('vehicles', [ 'saveVehicle', 'selectSinister' ]),
    ...mapActions('models', ['selectModels']),
    cancel() {
      this.VEHICLES_EDIT_MODE(false);
    },
    showValidationAlert() {
      let str = '<p>';
      if (this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', { confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true })
      }
    },
    async save() {
      try {
        const isNew = this.vehicle.id ? false : true;
        this.VEHICLES_ERROR_EMPTY()

        let result = await this.saveVehicle();
        if ( isNew == true ) {
          this.$notify({ title: 'Mensaje de confirmación', message: 'Vehiculo creado', type: 'success' })
        } else if (result == 'updated') {
          this.$notify({ title: 'Mensaje de confirmación', message: 'Vehiculo actualizado', type: 'success' })
        }
        this.VEHICLES_EDIT_MODE(false);
        setTimeout(() => {          
          this.$router.replace(`/home/sinisterDetail/${result.id}`)
        }, 600)
      } catch (err) {
        console.log(err)
        if (this.errors.length > 0) {
          this.showValidationAlert()
        }

        if (err.details != 'errores') {
          this.$notify({ title: 'Mensaje de error', message: `${err.details}`, type: 'error' })
        }
      }
    },
    changeProperty(value, property) {
      if (property == 'Mark') {
        if (this.vehicle.Model.idMark != value.id) {
          const cleanModel = structuredClone(model);
          this.VEHICLES_VEHICLE_PROPERTIES({ property: 'Model', value: cleanModel })
          this.VEHICLES_VEHICLE_PROPERTIES({ property: 'Mark', value })
          this.MODELS_PAGE(0);
          this.MODELS_MODEL_EMPTY();
          this.selectModels({ idMark: value.id });
        }
      } else if (property == 'Model') {
        value.Mark = structuredClone(this.vehicle.Model.Mark);
        this.VEHICLES_VEHICLE_PROPERTIES({ property: 'Model', value: value });
      } else if (property == 'domain') {
        this.VEHICLES_VEHICLE_PROPERTIES({ property: 'domain', value: value.toUpperCase() })
      } else {
        this.VEHICLES_VEHICLE_PROPERTIES({ property, value })
      }
    },
  }
}
</script>

<style scoped>
  .column { margin-right: 20px; }

  .row { margin-bottom: 10px; }

  .psFormAbm { padding: 15px 0; }

  .psFormAbm > .fields > .column { margin-right: 0;}
</style>