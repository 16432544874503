<template>
  <div class="psConfigurationItem">
    <span><i class="demo-icon" :class="icon" v-html="caracter"></i> </span>
    <p>{{item}}</p>
  </div>
</template>

<script>
export default {
  name: 'PsConfigurationItem',
  props: {
    icon: String,
    item: String,
    caracter: String
  }
}
</script>

<style scoped>
  .psConfigurationItem {
    width: 200px;
    height: 120px;
    /* border: 0.5px solid #eee; */
    box-shadow: 1px 1px 20px #ddd;
    border-radius: 5px;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .psConfigurationItem:hover {
    transform: scale(1.04);
    border-bottom: 5px solid #F706A1;
    box-shadow: 1px 1px 30px #bbb ;
  }

  .demo-icon { font-size: 2rem;}
  span { flex-grow: 1; display: flex; align-items: flex-end;  }
  p { flex-grow: 1; display: flex; align-items: center; font-size: 1.25rem; font-weight: 700;}
</style>