<template>
  <div class="psColors">
    <h2>Colores</h2>
    <ps-bar-buttons>
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="back"></ps-button>
      <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew"></ps-button>      
    </ps-bar-buttons>
    <ps-form-abm @save="save" @cancel="cancel" :disabledButton="disabledButton">
      <div class="column flex-row-align-left">
        <label>Código</label>
        <el-input placeholder="Código" disabled class="size-ss disabled" :value="color.id"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label>Descripción</label>
        <el-input placeholder="Color" class="size-l" :value="color.color" :disabled="!editMode"  @input="changeProperty($event, 'color')" :class="[ {disabled: !editMode}]"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label for="active">Activo</label>
        <el-switch 
          :value="color.active == 1 ? true : false" 
          :disabled="!editMode"
          class="check" 
          :class="[{disabled: !editMode}]" 
          @change="changeProperty(null, 'active')"></el-switch>
      </div>
    </ps-form-abm>

    <div class="sectionTable">
      <el-table
        :data="colors"
        highlight-current-row
        @current-change="selectRow"
        empty-text="Sin colores"
        height="95%">
        <el-table-column v-for="(item, index) in fields" :key="index" width="180" :prop="item.name" :label="item.label">
          <template slot-scope="scope">
            <el-tag  v-if="item.name == 'active'"
              :type="scope.row.active == 1 ? 'success' : 'danger'"
              disable-transitions>{{scope.row.active == 1 ? 'Activo' : 'Inactivo'}}</el-tag>
            <span v-else>{{scope.row[item.name]}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'
import PsFormAbm from '@/components/PsFormAbm.vue'
import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  name: 'PsColors',
  components: { PsBarButtons, PsButton, PsFormAbm },
  computed: {
    ...mapState('colors', ['color', 'colors', 'fields', 'editMode']),
    disabledButton () {
      return !this.color.color
    }
  },
  methods: {
    ...mapMutations('colors', ['COLORS_COLOR', 'COLORS_COLOR_PROPERTIES', 'COLORS_COLOR_EMPTY', 'COLORS_COLORS_EMPTY', 'COLORS_PAGE', 'COLORS_EDIT_MODE']),
    ...mapActions('colors', ['selectColors', 'createColor', 'updateColor']),
    psNew () {
      this.COLORS_EDIT_MODE(true);
      this.COLORS_COLOR_EMPTY();
    },
    selectRow(row) {
      console.log(row)
      this.COLORS_COLOR(structuredClone(row));
      this.COLORS_EDIT_MODE(true);
    },
    changeProperty (value, property) {   
      if( property == 'active') {
        this.COLORS_COLOR_PROPERTIES({property, value: !this.color.active })
      } else {
        this.COLORS_COLOR_PROPERTIES({property, value: value })
      }
    },
    back () {
      this.$router.go(-1)
      this.COLORS_COLOR_EMPTY();
      this.COLORS_EDIT_MODE(false);
    },
    save () {
      if(this.color.id) {
        this.updateColor()
          .then( () => this.$notify( { title: 'Mensaje de confirmación', message: 'Color actualizado', type: 'success'}))
          .then(() => this.COLORS_COLOR_EMPTY())
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe el color ${this.color.color}`
            } else {
              message = 'Error al crear o modificar un color';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
      } else {
        this.createColor()
          .then( () => this.$notify( { title: 'Mensaje de confirmación', message: 'Color creado', type: 'success'}))
          .then(() => {
            this.COLORS_COLOR_EMPTY();
            this.COLORS_EDIT_MODE(false);  
          })
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe el color ${this.color.color}`
            } else {
              message = 'Error al crear o modificar un color';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
      }
      
    },
    cancel () {
      this.COLORS_COLOR_EMPTY();
      this.COLORS_EDIT_MODE(false);
    }
  },
  async created () {
    this.COLORS_PAGE(0);
    this.COLORS_COLORS_EMPTY();
    await this.selectColors();
  }
}
</script>

<style scoped>

  .psColors { padding: 0 20px; height: 100%; display: flex; flex-direction: column; }

  h2 {
    margin: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    
  }

  .psBarButtons{ min-height: 40px; padding-left: 20px}
  .psFormAbm{ height: 25%; min-height: 160px;}
  .psTableAbm{ height: 53%;}

  .column { margin-right: 20px; }

  .column { margin-right: 20px; }
  .check { margin: 13px 0}
  .sectionTable { flex-grow: 1; }
</style>