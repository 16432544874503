<template>
  <div class="psSelectImageTemplateItem">
    <div class="container">
      <ps-image-template :item-number="itemNumber"></ps-image-template>
      <el-button class="btn" @click="selectImage" type="success" plain>Seleccionar</el-button>
    </div>
  </div>
</template>

<script>
import PsImageTemplate from './PsImageTemplate.vue';
import html2canvas from 'html2canvas';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'PsSelectImageTemplateItem',
  props: [ 'itemNumber' ],
  components: { PsImageTemplate },
  computed: {
    ...mapState('images', ['destination']),
  },  
  methods: {
    ...mapMutations('images', [ 'IMAGES_NAME_IMAGE', 'IMAGES_DESTINATION' ]),
    ...mapMutations([ 'SET_DRAWER_SELECT_TEMPLATE']),
    ...mapMutations('sinisters', ['SINISTERS_SINISTER_PROPERTIES']),
    ...mapMutations('vehicles', ['VEHICLES_VEHICLE_PROPERTIES']),
    ...mapActions('images', ['uploadImage']),
    ...mapActions('vehicles', ['updateImagenDominio']),
    ...mapActions('sinisters', ['updateImageVehicle', 'selectSinister']),
    base64toFile( dataurl, filename ) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
    },
    selectImage() {
      const item = document.querySelector(`#templateBuscado${this.itemNumber}`);
      let croppedCanvas = document.createElement('canvas');
      const scaleBy = 5;
      
      croppedCanvas.width  = item.clientWidth * scaleBy;
      croppedCanvas.height = item.clientHeight * scaleBy;
      croppedCanvas.style.width = item.clientWidth + 'px'; 
      croppedCanvas.style.height = item.clientHeight + 'px'; 
      let croppedCanvasContext = croppedCanvas.getContext('2d');
      croppedCanvasContext.scale(scaleBy, scaleBy);

      const destination = {
        type: 'vehicle', 
        property: `imageDomain`,
      } 
      
      this.IMAGES_DESTINATION(destination)
      
      html2canvas(item, { useCORS: true, canvas: croppedCanvas }).then(async canvas => {

        croppedCanvasContext.webkitImageSmoothingEnabled = false;
        croppedCanvasContext.mozImageSmoothingEnabled = false;
        croppedCanvasContext.imageImageSmoothingEnabled = false;            

        croppedCanvasContext.drawImage(canvas, 0, 0, item.clientWidth , item.clientHeight);

        const url = croppedCanvas.toDataURL();

        let fileImage = this.base64toFile( url, 'cropImage.jpg' );
        

        const filename = await this.uploadImage( { file: {file: fileImage }, ...destination  });
        
        if(this.destination.type == 'sinister') {
          this.SINISTERS_SINISTER_PROPERTIES({ property: this.destination.property, value: filename });
        }
        if(this.destination.type == 'vehicle') {
          this.VEHICLES_VEHICLE_PROPERTIES({ property: this.destination.property, value: filename.imageName });
        }
        this.IMAGES_DESTINATION(null);
        this.SET_DRAWER_SELECT_TEMPLATE(false);
      });
    }
  }
}
</script>

<style scoped>
  .psSelectImageTemplateItem { border: 1px solid rgb(191, 191, 191); margin: 10px; border-radius: 6px; transition: ease .2s all; }
  .psSelectImageTemplateItem:hover { transform: scale(1.04); box-shadow: 0 0 4px 4px #eee}
  .container { max-width: 250px; margin: 15px; }
  .btn { margin-top: 5px}
</style>