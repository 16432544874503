<template>
  <div class="imagesSinister">
    <ps-image-item  v-for="(image, index) of sinister.SinisterImage" :key="index" :index="index" :image="image" @deleteFile="deleteFile" width="210px" height="140px"></ps-image-item>

    <ps-upload @saveFilename="saveFilename" width="100px" height="140px"></ps-upload>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import PsUpload from './PsUpload.vue';
import PsImageItem from './PsImageItem.vue';

export default {
  name: 'PsImagesSinister',
  components: { PsUpload, PsImageItem },
  computed: {
    ...mapState('sinisters', ['sinister']),
  },
  methods: {
    ...mapMutations('sinisters', ['SINISTERS_SINISTER']),
    ...mapActions('sinisters', ['updateImageVehicle', 'selectSinister']),
    async deleteFile( index ) {
      this.$confirm('¿Está seguro que quiere eliminar esta imagen?', 'Confirmación', {
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        type: 'warning'
      })
      .then(async () => { 
        const sinister = structuredClone(this.sinister);
        sinister.SinisterImage.splice(index, 1);
        this.SINISTERS_SINISTER(sinister);
      })
      .catch(() => null);
    },
  }
}
</script>

<style scoped>
  .imagesSinister { display: flex; flex-wrap: wrap;}
</style>