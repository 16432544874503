<template>
  <div class="psMarks">
    <h2>Marcas</h2>
    <ps-bar-buttons>
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="back"></ps-button>
      <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew"></ps-button>      
    </ps-bar-buttons>
    <ps-form-abm @save="save" @cancel="cancel" :disabledButton="disabledButton">
      <div class="column flex-row-align-left">
        <label>Código</label>
        <el-input placeholder="Código" disabled class="size-ss disabled" :value="mark.id"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label>Descripción</label>
        <el-input placeholder="Marca del vehículo" class="size-l" :value="mark.mark" :disabled="!editMode"  @input="changeProperty($event, 'mark')" :class="[ {disabled: !editMode}]"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label for="active">Activo</label>
        <el-switch 
          :value="mark.active == 1 ? true : false" 
          :disabled="!editMode"
          class="check" 
          :class="[{disabled: !editMode}]" 
          @change="changeProperty(null, 'active')"
        ></el-switch>
      </div>
    </ps-form-abm>

    <div class="sectionTable">
      <el-table
        :data="marks.filter(m => m.id > 0)"
        highlight-current-row
        @current-change="selectRow"
        empty-text="Sin marcas"
        height="90%">
        <el-table-column v-for="(item, index) in fields" :key="index" width="180" :prop="item.name" :label="item.label">
          <template slot-scope="scope">
            <el-tag  v-if="item.name == 'active'"
              :type="scope.row.active == 1 ? 'success' : 'danger'"
              disable-transitions>{{scope.row.active == 1 ? 'Activo' : 'Inactivo'}}</el-tag>
            <span v-else>{{scope.row[item.name]}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'
import PsFormAbm from '@/components/PsFormAbm.vue'
import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  name: 'PsMarks',
  components: { PsBarButtons, PsButton, PsFormAbm },
  computed: {
    ...mapState('marks', ['mark', 'marks', 'fields', 'editMode']),
    disabledButton () {
      return !this.mark.mark
    }
  },
  methods: {
    ...mapMutations('marks', ['MARKS_MARK', 'MARKS_MARK_PROPERTIES', 'MARKS_MARKS_EMPTY', 'MARKS_MARK_EMPTY', 'MARKS_EDIT_MODE', 'MARKS_PAGE']),
    ...mapActions('marks', ['selectMarks', 'createMark', 'updateMark']),
    psNew () {
      this.MARKS_EDIT_MODE(true);
      this.MARKS_MARK_EMPTY();
    },
    selectRow(row) {
      this.MARKS_MARK(structuredClone(row));
      this.MARKS_EDIT_MODE(true);      
    },
    changeProperty (value, property) { 
      if( property == 'active') {
        this.MARKS_MARK_PROPERTIES({property, value: !this.mark.active })
      } else {
        this.MARKS_MARK_PROPERTIES({property, value: value })
      }
    },
    back () {
      this.$router.go(-1)
      this.MARKS_MARK_EMPTY();
      this.MARKS_EDIT_MODE(false);
    },
    save () {
      if(this.mark.id) {
        this.updateMark()
          .then( () => this.$notify( { title: 'Mensaje de confirmación', message: 'Marca actualizada', type: 'success'}))
          .then(() => this.MARKS_MARK_EMPTY())
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe la marca ${this.mark.mark}`
            } else {
              message = 'Error al crear o modificar una marca';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
        } else {
          this.createMark()
          .then( () => this.$notify( { title: 'Mensaje de confirmación', message: 'Marca creada', type: 'success'}))
          .then(() => {
            this.MARKS_MARK_EMPTY();
            this.MARKS_EDIT_MODE(false);
          })
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe la marca ${this.mark.mark}`
            } else {
              message = 'Error al crear o modificar una marca';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
      }
    },
    cancel () {
      this.MARKS_MARK_EMPTY();
      this.MARKS_EDIT_MODE(false);
    }
  },
  async created () {
    this.MARKS_PAGE(0);
    this.MARKS_MARKS_EMPTY();
    await this.selectMarks();
  }
}
</script>

<style scoped>

  .psMarks { padding: 0 20px; height: 100%; display: flex; flex-direction: column; }

  h2 {
    margin: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    
  }

  .psBarButtons{ min-height: 40px; padding-left: 20px}
  .psFormAbm{ height: 25%; min-height: 160px;}
  .psTableAbm{ height: 53%;}

  .column { margin-right: 20px; }

  .column { margin-right: 20px; }
  .check { margin: 13px 0}
  .sectionTable { flex-grow: 1; }
</style>