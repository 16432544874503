<template>
  <div class="psCompanies">
    <h2>Compañías aseguradoras</h2>
    <ps-bar-buttons>
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="back"></ps-button>
      <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew"></ps-button>      
    </ps-bar-buttons>
    <ps-form-abm @save="save" @cancel="cancel" :disabledButton="disabledButton">
      <div class="column flex-row-align-left">
        <label>Código</label>
        <el-input placeholder="Código" disabled class="size-ss disabled" :value="company.id"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label>Descripción</label>
        <el-input placeholder="Compañía" class="size-l" :value="company.company" :disabled="!editMode"  @input="changeProperty($event, 'company')" :class="[ {disabled: !editMode}]"></el-input>
      </div>
      <div class="column flex-row-align-left">
        <label for="active">Activo</label>
        <el-switch 
          :value="company.active == 1 ? true : false" 
          :disabled="!editMode"
          class="check" 
          :class="[{disabled: !editMode}]" 
          @change="changeProperty(null, 'active')"
        ></el-switch>
      </div>
    </ps-form-abm>

    <div class="sectionTable">
      <el-table
        :data="companies"
        highlight-current-row
        @current-change="selectRow"
        empty-text="Sin compañ{ias"
        height="95%"
        size="mini">
        <el-table-column v-for="(item, index) in fields" :key="index" width="180" :prop="item.name" :label="item.label">
          <template slot-scope="scope">
            <el-tag  v-if="item.name == 'active'"
              :type="scope.row.active == 1 ? 'success' : 'danger'"
              disable-transitions>{{scope.row.active == 1 ? 'Activo' : 'Inactivo'}}</el-tag>
            <span v-else>{{scope.row[item.name]}}</span>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <el-button icon="el-icon-view" size="mini" @click.native="$router.push(`/home/companiesContacts/${scope.row.id}`)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'
import PsFormAbm from '@/components/PsFormAbm.vue'
import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  name: 'PsCompanies',
  components: { PsBarButtons, PsButton, PsFormAbm },
  computed: {
    ...mapState('companies', ['companies', 'company', 'fields', 'editMode']),
    disabledButton () {
      return !this.company.company
    }
  },
  methods: {
    ...mapMutations('companies', ['COMPANIES_COMPANY', 'COMPANIES_COMPANY_PROPERTIES', 'COMPANIES_COMPANY_EMPTY', 'COMPANIES_COMPANIES_EMPTY', 'COMPANIES_EDIT_MODE', 'COMPANIES_PAGE']),
    ...mapActions('companies', ['selectCompanies', 'createCompany', 'updateCompany']),
    psNew () {
      this.COMPANIES_COMPANY_EMPTY()
      this.COMPANIES_EDIT_MODE(true);
    },
    selectRow(row) {
      if(row) {
        this.COMPANIES_COMPANY(row);
        this.COMPANIES_EDIT_MODE(true);
      }
    },
    changeProperty (value, property) {   
      if( property == 'active') {
        this.COMPANIES_COMPANY_PROPERTIES({property, value: !this.company.active })
      } else {
        this.COMPANIES_COMPANY_PROPERTIES({property, value })
      }
    },
    back () {
      this.$router.go(-1)
      this.COMPANIES_COMPANY_EMPTY();
      this.COMPANIES_EDIT_MODE(false);
    },
    save () {
      if(this.company.id) {
        this.updateCompany()
          .then( () => {
            this.COMPANIES_COMPANY_EMPTY();
            this.COMPANIES_EDIT_MODE(false);
            this.$notify( { title: 'Mensaje de confirmación', message: 'Compañía actualizada', type: 'success'})
          })
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe la compañía ${this.company.company}`
            } else {
              message = 'Error al crear o modificar una compañía';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
        } else {
        this.createCompany()
          .then( () => {
            this.COMPANIES_COMPANY_EMPTY();
            this.COMPANIES_EDIT_MODE(false);
            this.$notify( { title: 'Mensaje de confirmación', message: 'Compañía creada', type: 'success'})
          })
          .catch( (err) => {
            let message = '';
            if(err.response.data.errorCode == '001') {
              message = `Ya existe la compañía ${this.company.company}`
            } else {
              message = 'Error al crear o modificar una compañía';
            }
            this.$notify( { title: 'Mensaje de error', message, type: 'error'});
          })
      }
    },
    cancel () {
      this.COMPANIES_COMPANY_EMPTY();
      this.COMPANIES_EDIT_MODE(false);
    }
  },
  async created () {
    this.COMPANIES_PAGE(0);
    this.COMPANIES_COMPANIES_EMPTY();
    await this.selectCompanies();
  }
}
</script>

<style scoped>

  .psCompanies { padding: 0 20px; height: 100vh; display: flex; flex-direction: column; }

  h2 {
    margin: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    
  }

  .psBarButtons{ min-height: 40px; padding-left: 20px}
  .psFormAbm{ height: 25%; min-height: 160px;}
  .psTableAbm{ height: 53%;}

  .column { margin-right: 20px; }

  .column { margin-right: 20px; }
  .check { margin: 13px 0}
  .sectionTable { flex-grow: 1; }
</style>