import api from '@/services/api.js';
import { addProperyIfNotExists } from '@/services/utils.js';
import { getParamsUrl } from './Sinisters_utils';
import { sinister } from './sinister_model';
import { locality } from '../locality/locality_model';
import { company } from '../company/company_model';

const state = {
  sinisters: [],
  sinister: {},
  errors: [],
  vehicleSinisters: [],
  fields: [
    { name: 'id', label: 'ID', width: '100px' },
    { name: 'domain', label: 'Dominio', width: '100px' },
    { name: 'mark', label: 'Marca', width: '125px' },
    { name: 'model', label: 'Modelo', width: '130px' },
    { name: 'color', label: 'Color', width: '85px' },
    { name: 'year', label: 'Año', width: '75px' },
    { name: 'clientName', label: 'Nombre', width: '130px' },
    { name: 'clientSurname', label: 'Apellido', width: '125px' },
    { name: 'clientTelephone', label: 'Telefono', width: '110px' },
    { name: 'datetimeString', label: 'Fecha robo', width: '145px' },
    { name: 'status', label: 'Estado', width: '100px' },
    { name: 'chasisNumber', label: 'Nro Chasis', width: '150px' },
  ],
  page: 1,
  filters: {},
  states: [
    'Cancelado',
    'Encontrado',
    'Finalizado',
    'Recuperado',
    'Robado',
  ]
}

const mutations = {
  SINISTERS_SINISTERS(state, sinisters) { state.sinisters.push(...sinisters) },
  SINISTERS_SINISTER(state, sinister) { state.sinister = structuredClone(sinister) },
  SINISTERS_SINISTER_PROPERTIES(state, payload) { state.sinister[payload.property] = payload.value },
  SINISTERS_SINISTER_EMPTY(state) { state.sinister = structuredClone(sinister) },
  SINISTERS_SINISTERS_EMPTY(state) { state.sinisters = [] },
  SINISTERS_SINISTER_UNDEFINED(state) { state.sinister = {} },
  SINISTERS_ERROR(state, error) { state.errors.push(error) },
  SINISTERS_ERROR_EMPTY(state) { state.errors = [] },
  SINISTERS_PAGE(state, page) { state.page = page },
  SINISTERS_VEHICLE_SINISTERS(state, sinisters) { state.vehicleSinisters = sinisters },
  SINISTERS_FILTERS(state, filters) { state.filters = structuredClone(filters) }
}

const actions = {
  async selectSinisters({ commit }, params ) {
    try {
      let result = await api.get(`/sinister${getParamsUrl({ ...state.filters, ...params, page: state.page})}`)
      commit('SINISTERS_SINISTERS', result.data)
      commit('SINISTERS_PAGE', state.page + 1)
    } catch (err) { console.log(err) }
  },
  async selectSinister({ commit }, { sinisterId, setSinister = true }) {
    try {
      let result = await api.get(`/sinister/${sinisterId}`);
      const sinister = result.data;

      if(!sinister.Locality) {
        sinister.Locality = structuredClone(locality)
      }
      if(!sinister.Company) {
        sinister.Company = structuredClone(company)
      }
      commit('SINISTERS_SINISTER', sinister);
    } catch (err) { console.log(err); throw err.response.data.err.details; }
  },
  async updateSinister({ state }) {
    try {
      let result = await api.patch(`/sinister/${state.sinister.id}`, state.sinister)
      if( result.data ) {
        return result.data;
      }
    } catch (err) { throw err.response.data.err.details; }

  },
  async createSinister({ state, rootState }) {
    try {
      state.sinister.vehicleId = rootState.vehicles.vehicle.id;
      const result = await api.post('/sinister', state.sinister);
      if( result.data ) {
        return result.data;
      }
    } catch (err) { console.log({err}); throw err.response.data.err.details }

  },
  async saveSinister({ state, commit, dispatch }) {
    try {
      let s = state.sinister

      if (!s.Locality || !s.Locality.id) commit('SINISTERS_ERROR', 'La localidad es un dato requerido ')
      if (!s.Locality.Province || !s.Locality.Province.id) commit('SINISTERS_ERROR', 'La provincia es un dato requerido ')

      if (s.isCompany == false) {
        if (!s.clientName || s.clientName == '') commit('SINISTERS_ERROR', 'El nombre del cliente es un dato requerido ')
        if (!s.clientSurname || s.clientSurname == '') commit('SINISTERS_ERROR', 'El apellido del cliente es un dato requerido ')
        if (!s.clientTelephone || s.clientTelephone == '') commit('SINISTERS_ERROR', 'El telefono del cliente es un dato requerido ')
      }


      if (state.errors.length == 0) {
        if (state.sinister.id) {
          return await dispatch('updateSinister');
        } else {
          return await dispatch('createSinister');
        }
      } else {
        throw 'error'
      }
    } catch (err) {
      throw { err, details: err };
    }
  },
  async updateImageVehicle({ state }, { urlImage, destination } ) {
    try {
      await api.put(`/sinisters/image/${state.sinister.id}/${destination}`, { urlImage });
    } catch (err) { throw err.response.data.err.details; }
  },
  resetSinister({rootState, commit}) {
    const cleanSinister = structuredClone(sinister);
    cleanSinister.Vehicle = structuredClone(rootState.vehicles.vehicle);
    commit('SINISTERS_SINISTER', cleanSinister);
  }
}

const getters = {
  stateType: () => estado => {
    if( estado == 'Robado' ) {
      return 'danger';
    } else if( ['Recuperado', 'Encontrado'].includes(estado) ) {
      return 'success';
    } else if( estado == 'Finalizado' ) {
      return 'info';
    } else if( estado == 'Cancelado' ) {
      return 'warning';
    } else {
      return '';
    }
  },
}

export default { state, mutations, actions, getters, namespaced: true }