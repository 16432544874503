import { render, staticRenderFns } from "./PsButton.vue?vue&type=template&id=2ff38dfd&scoped=true"
import script from "./PsButton.vue?vue&type=script&lang=js"
export * from "./PsButton.vue?vue&type=script&lang=js"
import style0 from "./PsButton.vue?vue&type=style&index=0&id=2ff38dfd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff38dfd",
  null
  
)

export default component.exports