<template>
  <div id="filtroTabla">
    <div class="row filtro">
      <i class="el-icon-search"></i>
      <el-select :value="columnaAFiltrar" class="seleccionFiltro" placeholder="Seleccionar campo a filtrar" @change="seleccionarCampo" size="large">
        <el-option v-for="columna in columnas" :key="columna.campo" :label="columna.label" :value="columna.campo"></el-option>
      </el-select>
      <el-input id="filtro" :value="filtro" @input="ingresarValor" clearable placeholder="Escribir el valor a buscar" @keyup.native.enter="buscar" size="large" />
      <el-button icon="el-icon-search" circle @click="buscar" size="large"></el-button>
      <el-button icon="el-icon-close" circle @click="limpiarFiltros" size="large"></el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PsFiltroTabla',
  props: [ 'columnas', 'columnaAFiltrar', "filtro"],
  methods: {
    seleccionarCampo (campo) {
      this.$emit('seleccionarCampo', campo)
      this.$emit('ingresarValor', null)
    },
    ingresarValor (filtro) {
      this.$emit('ingresarValor', filtro)
    },
    buscar () {
      console.log('buscar')
      this.$emit('buscar')
    },
    limpiarFiltros () {
      this.$emit('ingresarValor', '')
      this.$emit('seleccionarCampo', '')
      this.buscar()
    }
  }
}
</script>

<style scoped>
  #filtroTabla { width: 600px; margin-right: 50px;}
  .filtro, .seleccionFiltro, .el-input { display: flex; align-items: center; }
  .el-icon-search { width: 60px; height: 40px; display: flex; justify-content: center; align-items: center; background-color: #F5F7FA; border: 1px solid rgb(220, 223, 230); border-radius: 6px 0 0 6px; border-right: none; }
  .el-icon-search:before { font-size: 1.5rem; color: grey}
  .el-select { width: 230px; margin-right: 15px;}
  .el-button { margin-left: 15px;}

</style>