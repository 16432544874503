<template>
  <div :id="`templateBuscado${itemNumber}`" class="psImageTemplate">
    <img :src="fullPathImage" :alt="`templateBuscado${itemNumber}`" class="img">
    <div class="content">
      <div class="domain">{{ vehicle.domain }}</div>
      <div class="contentVehicleData">
        <div class="vehicle">{{ vehicle.Model.Mark.mark }} {{ vehicle.Model.model }} - {{ vehicle.Color.color }}</div>
        <div class="vehicle2">Año: {{ vehicle.year }} - Nro Chasis: {{ vehicle.chasisNumber }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PsImageTemplate1',
  props: ['itemNumber'],
  computed: {
    ...mapState('vehicles', ['vehicle']),
    fullPathImage() {
      return `${process.env.VUE_APP_URL_IMAGES_VEHICLES}/templateBuscado${this.itemNumber}.jpg`;
    }
  },
}
</script>

<style scoped>
  .psImageTemplate { position: relative; height: 147px; }
  .content { position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 20px 20px 40px; }
  .domain { text-align: right; font-size: 24px; font-weight: bold; }

  .contentVehicleData { display: flex; flex-direction: column; align-items: flex-end; justify-content: center; height: 50px;}
  .vehicle { 
    text-align: right; 
    font-size: 13px; 
    overflow: hidden; 
    line-height: 15px; 
    color: black; 
    display: flex; 
    align-items: flex-start; 
    justify-content: flex-end; 
    height: 32px;
  }
  .vehicle2 { text-align: right; font-size: 10px; font-weight: normal; color: #777}

  .img { object-fit: cover; width: 100%; height: 100%;}
</style>