<template>
  <div class="PsSearchesDetail">
    <ps-bar-buttons>
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="back"></ps-button>
      <!-- <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew"></ps-button>       -->
    </ps-bar-buttons>

    <div class="form">
      <h2>Detalle de búsqueda</h2>
      <div class="row">
        <div class="column" >
          <div class="row">
            <div class="column size-l flex-column-align-left">
              <div class="label">{{search.searchType}}</div>
              <div class="data">{{search.search}}</div>
            </div>
            <div class="column size-l flex-column-align-left">
              <div class="label">Usuario</div>
              <div class="data">{{search.CompanyContact.name}} {{ search.CompanyContact.surname }}</div>
            </div>
            <div class="column size-m flex-column-align-left">
              <div class="label">Sector</div>
              <div class="data">{{search.CompanyContact.sector}}</div>
            </div>
          </div>
          <div class="row">
            <div class="column size-l flex-column-align-left">
              <div class="label">Email</div>
              <div class="data">{{search.CompanyContact.email}}</div>
            </div>
            <div class="column size-l flex-column-align-left">
              <div class="label">Teléfono</div>
              <div class="data">{{search.CompanyContact.telephone}}</div>
            </div>
          </div>
          <div class="row">
            <div class="column size-l flex-column-align-left">
              <div class="label">Compañía</div>
              <div class="data">{{search.CompanyContact.Company.company}}</div>
            </div>
            <div class="column size-l flex-column-align-left">
              <div class="label">Fecha/Hora</div>
              <div class="data">{{search.datetimeString}}</div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>  
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'

import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  name: 'PsSearchesDetail',
  components: { PsBarButtons, PsButton },
  computed: {
    ...mapState('searches', ['search']),
  },
  methods: {
    ...mapMutations('searches', ['SEARCHES_SEARCH']),
    ...mapActions('searches', [ 'selectSearch']),
    back () {
      this.$router.go(-1)
      this.SEARCHES_SEARCH({})
    },
  },
  async created () {
    if(this.$route.params['id'] > 0) {
      await this.selectSearch(this.$route.params['id'])
    } 
  },
  
}
</script>

<style scoped>

  .PsSearchesDetail { padding: 0 20px; height: 100%; display: flex; flex-direction: column;  }

  h2 {
    margin: 20px 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;    
  }

  .psBarButtons{ min-height: 40px; padding-left: 20px }
  .column { margin-right: 20px; }
  .row { margin-bottom: 10px;}

  .form { margin: 0 24px;}
</style>
