<template>
  <div class="psBarButtons">
    <slot>
      <button disabled>Sin acción</button>
    </slot>
  </div>
</template>

<script>

export default {
  name: 'PsBarButtons',
}
</script>

<style scoped>
  .psBarButtons {
    width: 100%;
    margin: 20px 0;
    padding: 0 20px;
    display: flex;
  }
</style>