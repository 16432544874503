import api from '@/services/api.js';
import { getParamsUrl } from './mark_utils';
import { mark } from './mark_model';

const state = {
  marks: [],
  mark: {},
  fields: [
    { name: 'id', label: 'Codigo', width: '120px' },
    { name: 'mark', label: 'Descripcion', width: '300px' },
    { name: 'active', label: 'Activo', width: '100px' },
  ],
  page: 1,
  filters: {},
  editMode: false
}

const mutations = {
  MARKS_MARKS(state, marks) { state.marks = marks },
  MARKS_MARK(state, mark) { state.mark = structuredClone(mark) },
  MARKS_MARK_PROPERTIES(state, payload) { state.mark[payload.property] = payload.value },
  MARKS_MARK_EMPTY(state) { state.mark = structuredClone(mark) },
  MARKS_MARKS_EMPTY(state) { state.marks = [] },
  MARKS_PAGE(state, page) { state.page = page },
  MARKS_FILTERS(state, filters) { state.filters = filters },
  MARKS_EDIT_MODE(state, editMode) { state.editMode = editMode }
}

const actions = {
  async selectMarks({ commit }, params) {
    try {
      let result = await api.get(`/mark${getParamsUrl({...state.filters, ...params, page: state.page })}`)
      commit('MARKS_MARKS', result.data)
    } catch (err) { console.log(err) }
  },
  async updateMark({ state, dispatch }) {
    const result = await api.patch(`/mark/${state.mark.id}`, state.mark)
    if (result && result.data) {
      dispatch('selectMarks')
    }
  },
  async createMark({ state, dispatch }) {
    const result = await api.post(`/mark`, state.mark)
    if (result && result.data) {
      dispatch('selectMarks')
    }
  }
}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }