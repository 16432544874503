<template>
  <div class="psLogin">
    <div class="column login">
      <div class="contentLogin">
        <h2>Acceso al panel de administración</h2>
        <div class="formLogin" @keyup.enter="login">
          <div class="column flex-row-align-left">
            <label>Usuario</label>
            <el-input class="size-m" v-model="usuario"></el-input>
          </div>
          <div class="column flex-row-align-left">
            <label>Contraseña</label>
            <el-input type="password" class="size-m" v-model="password"></el-input>
          </div>
          <el-button @click="login" :disabled="usuario == '' || password == ''">Ingresar</el-button>
        </div>
      </div>
    </div>
    <div class="column image"></div>

  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsLogin',
  data () {
    return {
      usuario: '',
      password: ''
    }
  },
  methods: {
    ...mapMutations(['SET_USERNAME']),
    ...mapActions(['loginHTTP']),
    async login () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Iniciando sesión...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });    
        await this.loginHTTP({usuario: this.usuario, password: this.password })
        this.$router.replace('/home/sinisters')
      } catch ( err ) { console.log(err); this.$message( { message: 'El usuario y/o la contraseña son incorrectos', type: 'error' }) }
      finally { loading.close(); }   
    }
  }
}
</script>

<style scoped>
  .psLogin { display: flex; height: 100%; }
  .column { height: 100%; flex-grow: 1;}
  .image { 
    background-image: url('./../assets/images/ciudad.jpg'); 
    background-repeat: no-repeat; 
    background-size: cover; 
    opacity: 0.6;
  }
  .login { display: flex; align-items: center; justify-content: center;}
  .formLogin { 
    height: 200px; 
    width: 350px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
  }
  h2 { font-size: 18px; font-weight: 700; margin-bottom: 30px; text-align: center;}
</style> 