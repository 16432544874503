<template>
  <div class="psSinister">
    <ps-sinister-edit></ps-sinister-edit>

    <div class="tableSinisters">
      <div class="row" style="font-size: 16px; font-weight: bold;">
        Siniestros
      </div>
      <el-table
        :data="vehicle.Sinister"
        border
        style="width: 1190px; height: 100%;"
      >
        <el-table-column prop="id" label="ID" width="70"></el-table-column>
        <el-table-column prop="stealingDatetime" label="Fecha Robo" width="160">
          <template slot-scope="scope">
              <span>{{scope.row.stealingDatetime | convertDDMMYYYYHHMM}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Province.province" label="Provincia" width="140"></el-table-column>
        <el-table-column prop="Locality.locality" label="Localidad" width="150"></el-table-column>
        <el-table-column prop="stealingZone" label="Zona robo" width="200"></el-table-column>
        <el-table-column prop="Company.company" label="Cía Aseguradora" width="140"></el-table-column>
        <el-table-column prop="stolenType" label="Tipo robo" width="140"></el-table-column>
        <el-table-column prop="status" label="Estado" width="105" align="center">
          <template slot-scope="scope">
            <el-tag :type="stateType(scope.row.status)">{{ scope.row.status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button icon="el-icon-edit" type="info" plain @click="edit(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import PsSinisterEdit from './PsSinisterEdit.vue';

export default {
  name: 'PsSinister',
  components: { PsSinisterEdit },
  computed: {
    ...mapState('vehicles', ['vehicle']),
    ...mapGetters('sinisters', ['stateType'])
  },
  methods: {
    ...mapMutations('sinisters', ['SINISTERS_SINISTER']),
    ...mapActions('sinisters', ['selectSinister']),
    async edit( row ) {
      await this.selectSinister({sinisterId: row.id })
    }
  }
  
}
</script>

<style scoped>
  .psSinister { margin: 20px 0}
  .tableSinisters { margin: 20px}
</style>