import api from '@/services/api.js';
import { getParamsUrl } from './color_utils';
import { color } from './color_model';

const state = {
  colors: [],
  color: {},
  fields: [
    { name: 'id', label: 'Codigo', width: '120px' },
    { name: 'color', label: 'Descripcion', width: '300px' },
    { name: 'active', label: 'Estado', width: '100px' },
  ],
  page: 1,
  filters: {},
  editMode: false
}

const mutations = {
  COLORS_COLORS(state, colors) { state.colors = colors },
  COLORS_COLOR(state, color) { state.color = structuredClone(color) },
  COLORS_COLOR_PROPERTIES(state, payload) { state.color[payload.property] = payload.value },
  COLORS_COLOR_EMPTY(state) { state.color = structuredClone(color) },
  COLORS_COLORS_EMPTY(state) { state.color = [] },
  COLORS_PAGE(state, page) { state.page = page },
  COLORS_FILTERS(state, filters) { state.filters = filters},
  COLORS_EDIT_MODE(state, editMode) { state.editMode = editMode }
}

const actions = {
  async selectColors({ commit }, params) {
    try {
      let result = await api.get(`/color${getParamsUrl({...state.filters, ...params, page: state.page })}`)
      commit('COLORS_COLORS', result.data)
    } catch (err) { console.log(err) }
  },
  async updateColor({ state, dispatch }) {
    const result = await api.patch(`/color/${state.color.id}`, state.color);
    if (result && result.data) {
      dispatch('selectColors')
    }
  },
  async createColor({ state, dispatch }) {
    const result = await api.post(`/color`, state.color)
    if (result && result.data) {
      dispatch('selectColors')
    }
  }
}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }