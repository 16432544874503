<template>
  <div class="psVehicleDetail">
    <ps-bar-buttons>
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="back"></ps-button>
    </ps-bar-buttons>
    <div class="vehicle">
      <div class="row" style="margin-bottom: 0; width: 100%;"
        :style="vehicle.id ? 'justify-content: space-between;' : ''">
        <div class="column">
          <div class="column flex-row-align-left" v-if="vehicle.id">
            <div style="font-size: 14px;">Dominio</div>
            <div class="row" style="height: 36px; line-height: 36px; width: 160px; justify-content: space-between;">
              <div class="vehicleDescription">{{ vehicle.domain }}</div>
              <el-button icon="el-icon-edit" circle type="info" plain @click="VEHICLES_EDIT_MODE(!editMode)"></el-button>
            </div>
          </div>
          <ps-sinister-vehicle-edit v-if="editMode == true"></ps-sinister-vehicle-edit>
          <template v-else>
            <div class="row">
              <div class="column">
                <ps-verify-domain v-if="!vehicle.id"></ps-verify-domain>
                <ps-sinister-vehicle v-else></ps-sinister-vehicle>
              </div>
            </div>
          </template>
        </div>
        <div class="column imagenDominio" :class="editMode ? 'imagenDominioEditMode' : ''" v-if="vehicle.id">
          <ps-image-domain :nameImage="fullPathImageDomain"></ps-image-domain>
        </div>
      </div>
    </div>
    
    <div class="row" style="padding-left: 23px;">      
      <el-button icon="el-icon-plus" type="primary" @click="resetSinister">Nuevo siniestro</el-button>
    </div>
    <ps-sinister v-if="vehicle.id"></ps-sinister>
  </div>
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue';
import PsButton from '@/components/PsButton.vue';
import PsSinisterVehicle from './widgets/PsSinisterVehicle.vue';
import PsSinisterVehicleEdit from './widgets/PsSinisterVehicleEdit.vue';
import PsVerifyDomain from './widgets/PsVerifyDomain.vue';
import PsImageDomain from './widgets/PsImageDomain.vue'
import { mapState, mapActions, mapMutations } from 'vuex';
import PsSinister from './widgets/PsSinister.vue';


export default {
  name: 'PsSinisterDetail',
  components: { PsBarButtons, PsButton, PsSinisterVehicle, PsSinisterVehicleEdit, PsVerifyDomain, PsSinister, PsImageDomain },
  computed: {
    ...mapState('vehicles', ['vehicle', 'editMode']),
    ...mapState('sinisters', ['sinister']),
    fullPathImageDomain() {
      return `${process.env.VUE_APP_URL_IMAGES_VEHICLES}/${this.vehicle.imageDomain}`
    }
  },
  methods: {
    ...mapActions('sinisters', ['selectSinister', 'resetSinister']),
    ...mapActions('localities', ['selectLocalities']),
    ...mapActions('provinces', ['selectProvinces']),
    ...mapActions('marks', ['selectMarks']),
    ...mapActions('models', ['selectModels']),
    ...mapActions('colors', ['selectColors']),
    ...mapActions('vehicles', ['selectVehiclesTypes', 'deleteImagenDominio', 'updateImagenDominio', 'selectVehicle']),
    ...mapActions('companies', ['selectCompanies']),
    ...mapMutations('vehicles', ['VEHICLES_VEHICLE_UNDEFINED', 'VEHICLES_VEHICLE_EMPTY', 'VEHICLES_EDIT_MODE', 'VEHICLES_VEHICLE_PROPERTIES']),
    ...mapMutations('sinisters', ['SINISTERS_SINISTER_EMPTY', 'SINISTERS_SINISTER_EMPTY']),
    ...mapMutations('localities', ['LOCALITIES_PAGE']),
    ...mapMutations('provinces', ['PROVINCES_PAGE']),
    ...mapMutations('marks', ['MARKS_PAGE']),
    ...mapMutations('models', ['MODELS_PAGE']),
    ...mapMutations('colors', ['COLORS_PAGE']),
    ...mapMutations('companies', ['COMPANIES_PAGE']),
    back() {
      this.$router.go(-1);
    },
    async deleteFile() {
      this.$confirm('¿Está seguro que quiere eliminar esta imagen?', 'Confirmación', {
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        type: 'warning'
      })
        .then(async () => {
          try {
            await this.deleteImagenDominio();
            this.$notify({ title: 'Mensaje de confirmación', message: 'Imagen eliminada', type: 'success' });
            if (this.$route.params['vehicleId'] > 0) {
              await this.selectSinister({ vehicleId: this.$route.params['vehicleId'], sinisterId: this.$route.params['sinisterId'] });
            }
          } catch (err) {
            this.$notify({ title: 'Mensaje de error', message: err.details, type: 'error' })
          }
        })
        .catch(() => null);
    },
    async saveFilename(filename) {
      await this.updateImagenDominio(filename);
      if (this.$route.params['vehicleId'] > 0) {
        await this.selectSinister({ vehicleId: this.$route.params['vehicleId'], sinisterId: this.$route.params['sinisterId'] });
      }
    },
  },
  destroyed() {
    this.VEHICLES_VEHICLE_UNDEFINED();
    this.SINISTERS_SINISTER_EMPTY();
    this.VEHICLES_EDIT_MODE(false);
  },
  async created() {
    this.LOCALITIES_PAGE(0);
    this.MODELS_PAGE(0)

    if (this.$route.params['vehicleId'] > 0) {
      await this.selectVehicle( this.$route.params['vehicleId'] );
      if(this.$route.params['sinisterId'] > 0) {
        await this.selectSinister({ sinisterId: this.$route.params['sinisterId'] });
        if(this.sinister.Locality && this.sinister.Locality.idProvince) {
          await this.selectLocalities({ idProvince: this.sinister.Locality.idProvince })
        }
        if(this.sinister.Vehicle && this.sinister.Vehicle.Model && this.sinister.Vehicle.Model.idMark) {
          await this.selectModels({ idMark: this.sinister.Vehicle.Model.idMark })
        }
      } else {
        this.SINISTERS_SINISTER_EMPTY();
      }
    } else {
      this.VEHICLES_EDIT_MODE(false);
      this.VEHICLES_VEHICLE_EMPTY();
      this.SINISTERS_SINISTER_EMPTY();
      await this.selectLocalities()
      await this.selectModels()
    }

    this.PROVINCES_PAGE(0);
    await this.selectProvinces()
    this.MARKS_PAGE(0)
    await this.selectMarks()
    this.COLORS_PAGE(0)
    await this.selectColors()
    await this.selectVehiclesTypes()
    this.COMPANIES_PAGE(0)
    await this.selectCompanies()
  },

}
</script>

<style scoped>
.psVehicleDetail {
  padding: 0 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 60px;
}

.psBarButtons {
  min-height: 40px;
}

.check {
  margin: 13px 0
}

.row {
  margin-bottom: 10px;
}

.vehicle {
  display: flex;
  margin: 0 20px;
  padding: 20px;
  background-color: #84aee755;
  border-radius: 6px;
  padding-bottom: 0
}

div.column.imagenDominio>div.psUpload {
  margin: 0;
}

.imagenDominioEditMode {
  justify-content: flex-end;
  padding-bottom: 20px
}</style>
