import Vue from 'vue'
import Vuex from 'vuex'
import api from '../services/api'
import colors from '../views/color/Colors'
import companies from '../views/company/Companies'
import found from '../views/found/Found'
import images from '../views/image/Image'
import localities from '../views/locality/Localities'
import marks from '../views/mark/Marks'
import models from '../views/model/Models'
import provinces from '../views/province/Provinces'
import searches from '../views/searches/Searches'
import sinisters from '../views/sinisters/Sinisters'
import users from '../views/user/Users'
import vehicles from '../views/vehicles/Vehicles'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isActiveSession: false,
    user: {},
    token: '',
    isOpenedDrawerSelectTemplate: false,
  },
  mutations: {
    LOGIN(state, payload) {
      localStorage.setItem('user', JSON.stringify(payload))
      state.user = payload
      state.isActiveSession = true
    },
    LOGOUT(state) {
      state.user = {}
      state.isActiveSession = false
      localStorage.removeItem('user')
      localStorage.clear('token')
    },
    SET_DRAWER_SELECT_TEMPLATE( state, value ) { state.isOpenedDrawerSelectTemplate = value }
  },
  actions: {
    async loginHTTP({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post('/auth', { usuario: payload.usuario, password: payload.password })
          .then(result => {
            if (result) {
              commit('LOGIN', result.data)
              resolve()
            }
          })
          .catch(err => { console.log(err); reject(err) })

      })
    },
    async activeSession({ commit }) {
      return new Promise((resolve, reject) => {
        let user = localStorage.getItem('user')
        if (user) {
          api.post('/auth/token', user)
            .then(result => {
              if (result) {
                commit('LOGIN', JSON.parse(JSON.stringify(result.data)))
                resolve()
              } else {
                commit('LOGOUT')
                reject()
              }
            })
            .catch((err) => { reject(err) })
        } else {
          reject()
        }
      })

    },
  },
  modules: {
    marks,
    models,
    colors,
    provinces,
    localities,
    users,
    vehicles,
    companies,
    found,
    searches,
    sinisters,
    images
  }
})
