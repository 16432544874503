<template>
  <div class="psConfiguration">
    <div class="container">
      <ps-configuration-item caracter="&#xe806;" icon="icon-bookmarks" item="Marcas" @click.native="goTo('home/marks')"></ps-configuration-item>
      <ps-configuration-item caracter="&#xe807;" icon="icon-key" item="Modelos" @click.native="goTo('home/models')"></ps-configuration-item>
      <ps-configuration-item caracter="&#xe809;" icon="icon-droplet" item="Colores" @click.native="goTo('home/colors')"></ps-configuration-item>
      <ps-configuration-item caracter="&#xe804;" icon="icon-compass" item="Provincias" @click.native="goTo('home/provinces')"></ps-configuration-item>
      <ps-configuration-item caracter="&#xe803;" icon="icon-location2" item="Localidades" @click.native="goTo('home/localities')"></ps-configuration-item>
      <ps-configuration-item caracter="&#xe805;" icon="icon-user" item="Usuarios" @click.native="goTo('home/users')"></ps-configuration-item>
      <ps-configuration-item caracter="&#xe80b;" icon="icon-star-empty" item="Aseguradoras" @click.native="goTo('home/companies')"></ps-configuration-item>
    </div>
  </div>
</template>

<script>
import PsConfigurationItem from '@/components/PsConfigurationItem.vue';

export default {
  name: 'PsConfiguration',
  components: { PsConfigurationItem },
  methods: {
    goTo(route) {
      this.$router.push(`/${route}`)
    }
  }
}
</script>

<style scoped>
  .psConfiguration {
    padding: 40px;
    display: flex;
    
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    width: 85%;
  }
</style>