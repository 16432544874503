import api from '@/services/api.js';
import { getParamsUrl } from './search_utils';

const state = {
  searches: [],
  search: {},
  fields: [
    { name: 'datetimeString', label: 'Fecha/Hora', width: '160px' },
    { name: 'company', label: 'Compañia', width: '200px' },
    { name: 'user', label: 'Usuario', width: '180px' },
    { name: 'searchType', label: 'Busqueda por', width: '180px' },
    { name: 'search', label: 'Busqueda', width: '180px' },
  ],
  page: 1,
  filters: {}
}

const mutations = {
  SEARCHES_SEARCHES(state, searches) { state.searches.push(...searches) },
  SEARCHES_SEARCHES_EMPTY(state) { state.searches = [] },
  SEARCHES_SEARCH(state, search) { state.search = Object.assign({}, search) },
  SEARCHES_PAGE(state, page) { state.page = page },
  SEARCHES_FILTERS(state, filters) { state.filters = filters },
}

const actions = {
  async selectSearches({ commit }, params) {
    const result = await api.get(`/company-search${getParamsUrl({ ...state.filters, ...params, page: state.page })}`);
    
    if(result.data) {
      commit('SEARCHES_SEARCHES', result.data);
      commit('SEARCHES_PAGE', state.page + 1);
    }
        
  },
  async selectSearch({ commit }, id) {
    const result = await api.get(`/company-search/${id}`);
    if(result.data) {
      commit('SEARCHES_SEARCH', result.data);
    }
  },

}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }