import api from '@/services/api.js';
import { found } from './found_model';
import { getParamsUrl } from './found_utils';

const state = {
  vehiclesFound: [],
  found: {},
  fields: [
    { name: 'domain', label: 'Dominio', width: '100px' },
    { name: 'recuperatorName', label: 'Nombre', width: '130px' },
    { name: 'recuperatorSurname', label: 'Apellido', width: '160px' },
    { name: 'recuperatorTelephone', label: 'Telefono', width: '120px' },
    { name: 'mark', label: 'Marca', width: '125px' },
    { name: 'model', label: 'Modelo', width: '130px' },
    { name: 'datetimeString', label: 'Fecha', width: '155px' },
  ],
  fieldsRequests: [
    { name: 'datetimeString', label: 'Fecha/Hora', width: '140' },
    { name: 'company', label: 'Compañia', width: '200' },
    { name: 'userCompany', label: 'Usuario', width: '200' },
    { name: 'sector', label: 'Sector', width: '180' },
    { name: 'email', label: 'Email', width: '250' },
    { name: 'telephone', label: 'Teléfono', width: '200' },
  ],
  page: 1,
  filters: {}
}

const mutations = {
  FOUNDS_VEHICLES_FOUND(state, vehiclesFound) { state.vehiclesFound.push(...vehiclesFound) },
  FOUNDS_FOUND(state, found) { state.found = structuredClone(found) },
  FOUNDS_FOUND_PROPERTIES(state, payload) { state.found[payload.property] = payload.value },
  FOUNDS_FOUND_EMPTY(state) { state.found = structuredClone(found) },
  FOUNDS_FOUND_UNDEFINED(state) { state.found = {} },
  FOUNDS_PAGE(state, page) { state.page = page },
  FOUNDS_FILTERS(state, filters) { state.filters = filters }
}

const actions = {
  async selectVehiclesFound({ commit }, params ) {
    console.log(params)
    const result = await api.get(`/vehicleFound${getParamsUrl({ ...state.filters, ...params, page: state.page })}`);
    if(result.data) {
      commit('FOUNDS_VEHICLES_FOUND', result.data);
      commit('FOUNDS_PAGE', state.page + 1)
    }
  },
  async selectVehicleFound({ commit }, id) {
    const result = await api.get(`/vehicleFound/${id}`);
    if(result.data) {
      commit('FOUNDS_FOUND', result.data);
    }
  },
  async updateVehicleFound() {
    return new Promise((resolve, reject) => {
      api.patch(`/vehicleFound/${state.found.id}`, state.found )
        .then(() => {
          resolve(true)
        })
        .catch(err => {
          reject(err.response.data.err.details)
        })
    })
  }
}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }