<template>
  <header class="psHeader">
    <div>
        <img src="@/assets/images/isologotipoCircars.svg" alt="" width="170" height="64">
      </div>
      <div><h1>Panel de administración</h1><span style="font-size: 9px; line-height: 9px; margin-left: 20px;">v2.1.0</span></div>
      <div>
        <div class="column user">
          <!-- <p>{{username}}</p> -->
          <el-button @click="cerrarSesion" class="session">
            <span class="username">{{user.user}}</span><br>
            <span>Cerrar sesión</span><br>
          </el-button>
        </div>
      </div>
  </header>    
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'PsHeader',
  data () {
    return {
      user: {}
    }
  },
  methods: {
    ...mapMutations(['LOGOUT']),
    cerrarSesion () {    
      this.LOGOUT();       
      this.$router.replace('/')
    },    
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'))
  }
}
</script>

<style scoped>
  /* HEADER */

  header { background-color: #fff; display: flex;  box-shadow: 2px 1px 4px #ccc; padding: 5px 0}
  header > div:nth-child(odd) { width: 200px;}
  header > div:nth-child(1) { padding-left: 25px; display: flex;}
  header > div:nth-child(2) { flex-grow: 3; display: flex; justify-content: center; align-items: center;}
  header h1 { font-size: 1.25rem; font-weight: 900;}
  .user { padding-right: 10px;}
  .user span { font-size: 0.9rem;}
  .user span.username { font-weight: bold; font-size: 1.15rem;}

  .session { padding: 9px 20px;}

</style>