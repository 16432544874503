import api from '@/services/api.js';
import { getParamsUrl } from './company_utils';
import { company } from './company_model';

const state = {
  companies: [],
  company: {},
  fields: [
    { name: 'id', label: 'Codigo', width: '120px' },
    { name: 'company', label: 'Descripcion', width: '300px' },
    { name: 'active', label: 'Estado', width: '100px' },
  ],
  usersCompany: [],
  userCompany: {},
  errors: [],
  action: '',
  page: 1,
  filters: {},
  editMode: false,
}

const mutations = {
  COMPANIES_COMPANIES(state, companies) { state.companies = companies },
  COMPANIES_COMPANY(state, company) { state.company = structuredClone(company) },
  COMPANIES_COMPANY_PROPERTIES(state, payload) { state.company[payload.property] = payload.value },
  COMPANIES_COMPANY_EMPTY(state) { state.company = structuredClone(company) },
  COMPANIES_USERS_COMPANY(state, usersCompany) { state.usersCompany = usersCompany },
  COMPANIES_USER_COMPANY(state, userCompany) { state.userCompany = Object.assign({}, userCompany) },
  COMPANIES_USER_COMPANY_PROPERTIES(state, payload) { state.userCompany[payload.property] = payload.value },
  COMPANIES_USER_COMPANY_EMPTY(state) { state.userCompany = Object.assign({}, state.newUserCompany) },
  COMPANIES_ERROR_EMPTY(state) { state.errors = [] },
  COMPANIES_ERROR(state, error) { state.error.push(error) },
  COMPANIES_ACTION(state, action) { state.action = action },
  COMPANIES_PAGE(state, page) { state.page = page },
  COMPANIES_FILTERS(state, filters) { state.filters = filters },
  COMPANIES_EDIT_MODE( state, editMode ) { state.editMode = editMode }
}

const actions = {
  async selectCompanies({ commit }, params ) {
    try {
      let result = await api.get(`/company${getParamsUrl({...state.filters, ...params, page: state.page })}`)
      commit('COMPANIES_COMPANIES', result.data)
    } catch (err) { console.log(err) }
  },
  async updateCompany({ state, dispatch }) {
    const result = await api.patch(`/company/${state.company.id}`, state.company)
    if (result && result.data) {
      dispatch('selectCompanies')
    }
  },
  async createCompany({ state, dispatch }) {
    const result = await api.post(`/company`, state.company)
    if (result && result.data) {
      dispatch('selectCompanies')
    }
  },
  async saveUserCompany({ state, commit, dispatch }, companyId) {
    try {
      const uc = state.userCompany
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!uc.name || uc.name == '') commit('COMPANIES_ERROR', 'El nombre del usuario es un dato requerido.');
      if (!uc.surname || uc.surname == '') commit('COMPANIES_ERROR', 'El apellido del usuario es un dato requerido.');
      if (!uc.email || uc.email == '') commit('COMPANIES_ERROR', 'El correo electrónico es un dato requerido.');
      if (!uc.email.match(validRegex)) commit('COMPANIES_ERROR', 'El correo electrónico no parece ser válido.');

      if (state.errors.length == 0) {
        if (state.userCompany.id) {
          await dispatch('updateUserCompany')
          return 'update'
        } else {
          await dispatch('createUserCompany', companyId)
          return 'create'
        }
      } else {
        throw 'error'
      }
    } catch (err) {
      if (state.userCompany.id == 0) {
        throw { err, details: err }
      } else {
        throw { err, details: err }
      }
    }
  },
  async selectUsersCompany({ commit, state }, companyId) {
    try {
      let result = await api.get(`/company-contact?page=0&companyId=${companyId}`)
      if (result.data) {
        commit('COMPANIES_USERS_COMPANY', result.data);
      }
    } catch (err) { throw err.response.data.err.details; }

  },
  async updateUserCompany({ state, dispatch }) {
    try {
      let result = await api.patch(`/company-contact/${state.userCompany.id}`, state.userCompany)
      if (result.data) {
        await dispatch('selectUsersCompany', state.userCompany.companyId)
      }
    } catch (err) { throw err.response.data.err.details; }

  },
  async createUserCompany({ state, dispatch }) {
    try {
      let result = await api.post('/company-contact', state.userCompany)
      if (result.data) {
        await dispatch('selectUsersCompany', state.company.id)
      }
    } catch (err) { throw err.response.data.err.details }
  },
  async actionUserCompany({ state }, userCompanyId) {
    try {
      console.log({ userCompanyId, action: state.action })
      await api.post('/companies/action', { userCompanyId, action: state.action })
      return
    } catch (err) { throw err.response.data.err.details }
  },
}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }