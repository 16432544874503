<template>
  <div class="PsSearches">
    <h2>Búsquedas realizadas por compañias</h2>
    <ps-bar-buttons>
      <!-- <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew" id="btnNew"></ps-button>   -->
      <ps-filtro-tabla 
        :columnaAFiltrar="columnaAFiltrar" 
        :filtro="filtro" 
        :columnas="columnas" 
        @seleccionarCampo="seleccionarCampo" 
        @ingresarValor="ingresarValor" 
        @buscar="buscar"></ps-filtro-tabla>   
    </ps-bar-buttons>    

    <div class="sectionTable">
      <el-table
        class="tableVehicles"
        :data="searches"
        highlight-current-row
        @current-change="selectRow"
        empty-text="Sin búsquedas"
        size="mini"
        :cell-style="{padding: '6px 0', height: '20px'}"
        style="width: 100%"
        height="95%">
        <el-table-column v-for="(item, index) in fields" :key="index" :width="item.width" :prop="item.name" :label="item.label" sortable></el-table-column>
        <infinite-loading :distance="200" :identifier="infiniteId" @infinite="infiniteHandler" slot="append" force-use-infinite-wrapper=".el-table__body-wrapper" >
          <div slot="no-more" class="noMore">No hay más vehículos encontrados.</div>
        </infinite-loading>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsFiltroTabla from '@/components/PsFiltroTabla.vue'
import InfiniteLoading from 'vue-infinite-loading';
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'PsSearches',
  data () {
    return {
      columnaAFiltrar: '',
      filtro: '',
      infiniteId: +new Date(),
      columnas: [
        { campo: 'domain', label: 'Dominio' },
        { campo: 'chasisNumber', label: 'Nro Chasis' },
        { campo: 'user', label: 'Usuario' },
        { campo: 'company', label: 'Compañia' },
      ]
    }
  },
  components: { PsBarButtons, PsFiltroTabla, InfiniteLoading },
  computed: {
    ...mapState('searches', [ 'searches', 'fields' ]),
    
  },
  methods: {
    ...mapMutations('searches', [ 'SEARCHES_PAGE', 'SEARCHES_SEARCHES_EMPTY' ]),
    ...mapActions('searches', ['selectSearches' ]),
    selectRow(row) {
      this.$router.push(`/home/searchDetail/${row.id}`)
    },
    seleccionarCampo (campo) {
      this.columnaAFiltrar = campo
    },
    ingresarValor (valor) {
      this.filtro = valor
    },
    async buscar () {
      this.SEARCHES_SEARCHES_EMPTY()
      this.SEARCHES_PAGE(1); 
      const filter = {};
      filter[this.columnaAFiltrar] = this.filtro;
      this.SEARCHES_FILTERS(filter);
      await this.selectSearches()
    },
    async infiniteHandler ($state) {
      try {
        let complete = await this.selectSearches()
        if ( complete == true ) {
          $state.complete()
        } else {
          $state.loaded()
        }
      } catch ( err ) { return err }
    }
  },
  async created () {
    this.SEARCHES_SEARCHES_EMPTY()
    this.SEARCHES_PAGE(1); 
  }
}
</script>

<style scoped>
  .PsSearches { padding: 0 20px; height: 100%; display: flex; flex-direction: column; }

  h2 {
    margin: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    
  }

  .psBarButtons{ min-height: 40px; padding-left: 20px}
  .psFormAbm{ height: 25%; min-height: 220px;}
  .psTableAbm{ height: 53%;}

  .column { margin-right: 20px; }

  .column { margin-right: 20px; }
  .check { margin: 13px 0}
  .sectionTable { flex-grow: 1; }
</style>