import api from '@/services/api.js';
import { getParamsUrl } from './image_utils';

const state = {
  crop: false,
  destination: '',
  nameImage: '',
}

const mutations = {
  IMAGES_CROP(state) { state.crop = !state.crop },
  IMAGES_DESTINATION(state, destination) { state.destination = destination },
  IMAGES_NAME_IMAGE(state, nameImage) { state.nameImage = nameImage }
}

const actions = {  
  async uploadImage({ rootState }, params) {
    try {
      console.log(params)
      var formData = new FormData()
      formData.append('file', params.file.file);

      let result = await api.post(`/upload${getParamsUrl(params)}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }, { timeout: 20 });
      
      if (result.status == 201) {
        return result.data;
      }

    } catch (err) { console.log(err) }
  },
}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }