import api from '@/services/api.js'
import { getParamsUrl } from './user_utils';
import { user } from './user_model';

const state = {
  users: [],
  user: {},
  fields: [
    { name: 'id', label: 'Codigo', width: '120px' },
    { name: 'user', label: 'Usuario', width: '300px' },
    { name: 'active', label: 'Estado', width: '100px' },
  ],
  editMode: false,
  page: 1,
  filters: {},
  changePassword: false
}

const mutations = {
  USERS_USERS(state, users) { state.users = users },
  USERS_USERS_EMPTY(state) { state.users = [] },
  USERS_USER(state, user) { state.user = structuredClone(user) },
  USERS_USER_PROPERTIES(state, payload) { state.user[payload.property] = payload.value },
  USERS_USER_EMPTY(state) { state.user = structuredClone(user) },
  USERS_EDIT_MODE(state, editMode) { state.editMode = editMode },
  USERS_PAGE(state, page) { state.page = page },
  USERS_FILTERS(state, filters) { state.filters = filters },
  USERS_CHANGE_PASSWORD(state, changePassword) { state.changePassword = changePassword  }
}

const actions = {
  async selectUsers({ commit, state }, params) {
    try {
      let result = await api.get(`/user${getParamsUrl({...state.filters, params, page: state.page })}`)
      if(result.data) {
        commit('USERS_USERS', result.data);
      }
    } catch (err) { console.log(err) }
  },
  async updateUser({ state, dispatch }) {
    const { repeatPassword, ...user } = state.user;
    const result = await api.patch(`/user/${state.user.id}`, user)
    if (result && result.data) {
      dispatch('selectUsers')
    }
  },
  async createUser({ state, dispatch }) {
    const { repeatPassword, ...user } = state.user;
    const result = await api.post(`/user`, user)
    if (result && result.data) {
      dispatch('selectUsers')
    }
  },
}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }