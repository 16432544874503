<template>
  <div class="psSinisters">
    <h2>Siniestros</h2>
    <ps-bar-buttons>
      <ps-button icon="icon-add" caracter="&#xe80f;" text="Nuevo" type="mono" @click.native="psNew" id="btnNew"></ps-button>  
      <ps-filtro-tabla 
        :columnaAFiltrar="columnaAFiltrar" 
        :filtro="filtro" 
        :columnas="columnas" 
        @seleccionarCampo="seleccionarCampo" 
        @ingresarValor="ingresarValor" 
        @buscar="buscar"></ps-filtro-tabla>   
      <el-button round plain type="warning" @click="filtrarEstado('robado')" size="large">Robados</el-button>
      <el-button round plain type="success" @click="filtrarEstado('recuperado')" size="large">Recuperados</el-button>
    </ps-bar-buttons>    

    <div class="sectionTable">
      <el-table
        class="tableSinisters"
        :data="sinisters"
        highlight-current-row
        @current-change="selectRow"
        empty-text="Sin siniestros"
        size="mini"
        :cell-style="{padding: '6px 0', height: '20px'}"
        style="width: 100%"
        height="95%">
        <el-table-column v-for="(item, index) in fields" :key="index" :width="item.width" :prop="item.name" :label="item.label" sortable>
          <template slot-scope="scope">
            <el-tag  v-if="item.name == 'active'"
              :type="scope.row.active == 1 ? 'success' : 'danger'"
              disable-transitions>{{scope.row.active == 1 ? 'Activo' : 'Inactivo'}}</el-tag>
            <span v-else>{{scope.row[item.name]}}</span>
          </template>
        </el-table-column>
        <infinite-loading :distance="200" :identifier="infiniteId" @infinite="infiniteHandler" slot="append" force-use-infinite-wrapper=".el-table__body-wrapper" >
          <div slot="no-more" class="noMore">No hay más vehículos.</div>
        </infinite-loading>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'
import PsFiltroTabla from '@/components/PsFiltroTabla.vue'
import InfiniteLoading from 'vue-infinite-loading';
import { mapState, mapActions, mapMutations } from 'vuex'


export default {
  name: 'PsVehicles',
  data () {
    return {
      columnaAFiltrar: '',
      filtro: '',
      infiniteId: +new Date(),
      columnas: [
        { campo: 'domain', label: 'Dominio' },
        { campo: 'mark', label: 'Marca' },
        { campo: 'model', label: 'Modelo' },
        { campo: 'color', label: 'Color' },
        { campo: 'year', label: 'Año' },
        { campo: 'clientName', label: 'Nombre' },
        { campo: 'clientSurname', label: 'Apellido' },
        { campo: 'clientTelephone', label: 'Telefono' },
        // { campo: 'fechaRoboString', label: 'Fecha robo' },
        { campo: 'status', label: 'Estado' },
      ]
    }
  },
  components: { PsBarButtons, PsButton, PsFiltroTabla, InfiniteLoading },
  computed: {
    ...mapState('sinisters', [ 'sinisters', 'fields']),
    
  },
  methods: {
    ...mapMutations('sinisters', [ 'SINISTERS_SINISTERS_EMPTY', 'SINISTERS_SINISTER_EMPTY', 'SINISTERS_PAGE', 'SINISTERS_FILTERS' ]),
    ...mapActions('sinisters', ['selectSinisters' ]),
    psNew () {
      this.SINISTERS_SINISTER_EMPTY()
      this.$router.push('/home/sinisterDetail/0')
    },
    selectRow(row) {
      this.$router.push(`/home/sinisterDetail/${row.vehicleId}/${row.id}`)
    },
    seleccionarCampo (campo) {
      this.columnaAFiltrar = campo
    },
    ingresarValor (valor) {
      this.filtro = valor
    },
    async buscar () {
      this.SINISTERS_SINISTERS_EMPTY();
      this.SINISTERS_PAGE(1); 
      const filter = { grouped: true };
      filter[this.columnaAFiltrar] = this.filtro
      this.SINISTERS_FILTERS(filter)
      await this.selectSinisters()
    },
    async filtrarEstado (estado) {
      this.columnaAFiltrar = 'estado'
      this.filtro = estado
      await this.buscar()
    },
    async infiniteHandler ($state) {
      try {
        let complete = await this.selectSinisters( { campo: this.columnaAFiltrar, filtro: this.filtro, grouped: true })
        if ( complete == true ) {
          $state.complete()
        } else {
          $state.loaded()
        }
      } catch ( err ) { return err }
    }
  },
  async created () {
    this.SINISTERS_PAGE(1);
    this.SINISTERS_SINISTERS_EMPTY()
  }
}
</script>

<style scoped>

  .psSinisters { padding: 0 20px; height: 100%; display: flex; flex-direction: column; }

  h2 {
    margin: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    
  }

  #btnNew { margin-right: 20px;}

  .psBarButtons{ min-height: 40px; padding-left: 20px}
  .psFormAbm{ height: 25%; min-height: 220px;}
  .psTableAbm{ height: 53%;}

  .column { margin-right: 20px; }

  .column { margin-right: 20px; }
  .check { margin: 13px 0}
  .sectionTable { flex-grow: 1; }

</style>

<style>
  div.vehicleDescription {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
  }
</style>