<template>
  <div class="psCompaniesContacts">
    <div class="row">
      <ps-button icon="icon-angle-left" caracter="&#xf104;" text="Volver" type="mono" @click.native="$router.go(-1)" style="margin: 10px 0"></ps-button>   
      <h2>Usuarios compañías aseguradoras</h2>
    </div>
    <hr>
    <div class="form">
      <h3 style="display:flex; align-self: flex-start; font-weight: bold; line-height: 26px; font-size: 18px; margin-bottom: 25px">Usuario</h3>
      <div class="row">
        <div class="column size-m">
          <div class="label">Nombre</div>
          <el-input :value="userCompany.name" @input="changeProperty($event, 'name')" placeholder="Nombre"></el-input>
        </div>
        <div class="column size-m">
          <div class="label">Apellido</div>
          <el-input :value="userCompany.surname" @input="changeProperty($event, 'surname')" placeholder="Apellido"></el-input>
        </div>
        <div class="column size-xl">
          <div class="label">Email</div>
          <el-input 
            :value="userCompany.email" 
            @input="changeProperty($event, 'email')" 
            placeholder="Email"
            :disabled="userCompany.id > 0">
          </el-input>
        </div>
      </div>
      <div class="row">
        <div class="column size-m">
          <div class="label">Telefono</div>
          <el-input :value="userCompany.telephone" @input="changeProperty($event, 'telephone')" placeholder="Teléfono"></el-input>
        </div>
        <div class="column size-m">
          <div class="label">Sector</div>
          <el-input :value="userCompany.sector" @input="changeProperty($event, 'sector')" placeholder="Apellido"></el-input>
        </div>
        <div class="column size-s" v-if="userCompany.id > 0">
          <div class="label">Activo</div>
          <el-switch :value="userCompany.active" @input="changeProperty($event, 'active')"></el-switch>
        </div>
      </div>
      <div class="row">
        <el-button icon="el-icon-close" type="default" @click.native="COMPANIES_USER_COMPANY_EMPTY()">Cancelar</el-button>
        <el-button 
          :disabled="!disabledInviteButton"
          icon="el-icon-check" 
          type="success" 
          @click.native="save">
            {{userCompany.id > 0 ? 'Actualizar usuario' : 'Invitar usuario'}}
        </el-button>
      </div>
    </div>
    <hr>
    <el-table
      :data="usersCompany"
      style="margin-top: 40px"
      size="mini"
      highlight-current-row
      height="400"
      @current-change="selectRow">
        <el-table-column prop="name" label="Nombre" width="120"></el-table-column>
        <el-table-column prop="surname" label="Apellido" width="160"></el-table-column>
        <el-table-column prop="email" label="Email" width="320"></el-table-column>
        <el-table-column prop="telephone" label="Teléfono" width="120"></el-table-column>
        <el-table-column prop="sector" label="Sector" width="120"></el-table-column>
        <el-table-column prop="pending" label="Pendiente" width="100"></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-select @change="doAction($event, scope.row.id)" :value="action">
              <el-option value="resetPassword" label="Resetear password"><i class="el-icon-delete" style="margin-right: 10px"></i>Resetear password</el-option>
              <el-option value="resendInvite" label="Reenviar invitación"><i class="el-icon-message" style="margin-right: 10px"></i>Reenviar invitación</el-option>
            </el-select>
          </template>
        </el-table-column>

    </el-table>
  </div>
</template>

<script>
// import PsBarButtons from '@/components/PsBarButtons.vue'
import PsButton from '@/components/PsButton.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsCompaniesContacts',
  components: { PsButton },
  computed: {
    ...mapState('companies', ['userCompany', 'errors', 'usersCompany', 'action']),
    disabledInviteButton() {
      return this.userCompany.name != '' && this.userCompany.surname != '' && this.userCompany.email != ''
    }
  },
  methods: {
    ...mapMutations('companies', ['COMPANIES_USER_COMPANY_PROPERTIES', 'COMPANIES_USER_COMPANY_EMPTY', 'COMPANIES_ERROR_EMPTY', 'COMPANIES_USER_COMPANY',
      'COMPANIES_ACTION']),
    ...mapActions('companies', ['saveUserCompany', 'selectUsersCompany', 'actionUserCompany']),
    changeProperty( value, property ) {
      this.COMPANIES_USER_COMPANY_PROPERTIES({value, property});
    },
    doAction(value, userCompanyId) {
      console.log(value, userCompanyId);
      const user = this.usersCompany.find(u => u.id == userCompanyId );
      this.COMPANIES_ACTION(value);
      this.$confirm(`Se enviará un correo electrónico a ${user.email} para que el usuario ${this.action == 'resetPassword' ? ' resetee su contraseña' : ' active su cuenta'}.`,
        'Confirmación', {
          type: 'warning',
          confirmButtonText: 'Sí',
          cancelButtonText: 'No'
        })
        .then( async () => {
          await this.actionUserCompany(userCompanyId);
          this.$notify({title: 'Acción solicitada', message: 'Correo enviado!', type: 'success'})
          this.COMPANIES_ACTION('');
        })
        .catch(() => null)
      
    },
    selectRow(row) {
      if(row) {
        this.COMPANIES_USER_COMPANY(row);
      }
    },
    async save() {
      try {
        this.COMPANIES_ERROR_EMPTY()

        let result = await this.saveUserCompany(this.$route.params.companyId)
        
        if(result == 'create') {
          this.$notify( { title: 'Mensaje de confirmación', message: 'Usuario creado', type: 'success'})
        } else if (result == 'update') {
          this.$notify( { title: 'Mensaje de confirmación', message: 'Usuario actualizado', type: 'success'})
        }
        this.COMPANIES_USER_COMPANY_EMPTY();
      } catch ( err ) { 
        if(this.errors.length > 0) {
          this.showValidationAlert()
        }
        
        if(err.details != 'errores') {
          console.log({err})
          this.$notify( { title: 'Mensaje de error', message: `${err.details}`, type: 'error'})
        }
      }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },  
  },
  async created() {
    this.COMPANIES_USER_COMPANY_EMPTY();
    this.COMPANIES_ACTION('');
    await this.selectUsersCompany(this.$route.params.companyId)
  }
}
</script>

<style scoped>
 .psCompaniesContacts { padding: 0 20px 30px; height: 100%; display: flex; flex-direction: column; }

  h2 { margin: 20px ; font-size: 1.5rem; font-weight: 700; text-align: left; }

  .column { align-items: flex-start; margin-right: 20px;}

  .form { margin: 20px; }

  .form > .row { margin-bottom: 15px; }

</style>