import { pagination_params } from "../../shared/utils/pagination_params";

export const getParamsUrl = (params) => {
  if(!params) return '';
  let path = '';
  path = pagination_params(params);

  params.domain && (path = `${path != '' ? path + '&' : ''}domain=${params.domain}`);
  params.clientName && (path = `${path != '' ? path + '&' : ''}clientName=${params.clientName}`);
  params.clientSurname && (path = `${path != '' ? path + '&' : ''}clientSurname=${params.clientSurname}`);
  params.clientTelephone && (path = `${path != '' ? path + '&' : ''}clientTelephone=${params.clientTelephone}`);
  params.status && (path = `${path != '' ? path + '&' : ''}status=${params.status}`);
  params.year && (path = `${path != '' ? path + '&' : ''}year=${params.year}`);
  params.mark && (path = `${path != '' ? path + '&' : ''}mark=${params.mark}`);
  params.model && (path = `${path != '' ? path + '&' : ''}model=${params.model}`);
  params.color && (path = `${path != '' ? path + '&' : ''}color=${params.color}`);
  params.chasisNumber && (path = `${path != '' ? path + '&' : ''}chasisNumber=${params.chasisNumber}`);
  params.id && (path = `${path != '' ? path + '&' : ''}id=${params.id}`);
  params.grouped && (path = `${path != '' ? path + '&' : ''}grouped=${params.grouped}`);
  
  return `?${path}`;
}