import api from '@/services/api.js'
import { getParamsUrl } from './locality_utils';
import { locality } from './locality_model';

const state = {
  localities: [],
  locality: {},
  fields: [
    { name: 'id', label: 'Codigo', width: 120 },
    { name: 'province', label: 'Provincia', width: 300 },
    { name: 'locality', label: 'Localidad', width: 350 },
    { name: 'active', label: 'Activo', width: 100 },
  ],
  page: 1,
  filters: {},
  editMode: false,
}

const mutations = {
  LOCALITIES_LOCALITIES(state, localities) { state.localities = localities },
  LOCALITIES_LOCALITIES_EMPTY(state) { state.localities = [] },
  LOCALITIES_LOCALITY(state, locality) { state.locality = structuredClone(locality) },
  LOCALITIES_LOCALITY_PROPERTIES(state, payload) { state.locality[payload.property] = payload.value },
  LOCALITIES_LOCALITY_EMPTY(state) { state.locality = structuredClone(locality) },
  LOCALITIES_LOCALITY_UNDEFINED(state) { state.locality = {} },
  LOCALITIES_PAGE(state, page) { state.page = page },
  LOCALITIES_FILTERS(state, filters) { state.filters = filters },
  LOCALITIES_EDIT_MODE(state, editMode) { state.editMode = editMode }
}

const actions = {
  async selectLocalities({ commit, state }, params) {
    try {
      let result = await api.get(`/locality${getParamsUrl({...state.filters, ...params, page: state.page })}`)
      commit('LOCALITIES_LOCALITIES', result.data)
    } catch (err) { console.log(err) }
  },
  async updateLocality({ state, dispatch }) {
    const result = await api.patch(`/locality/${state.locality.id}`, state.locality)
    if (result && result.data) {
      dispatch('selectLocalities')
    }
  },
  async createLocality({ state, dispatch }) {
    const result = await api.post(`/locality`, state.locality)
    if (result && result.data) {
      dispatch('selectLocalities')
    }
  }
}

const getters = {

}

export default { state, mutations, actions, getters, namespaced: true }