<template>
  <div class="psSinisterEdit">
    <div class="row" style="font-size: 16px; font-weight: bold; line-height: 30px;">
      Siniestro
      <div class="column flex-row-align-left size-mm" style="margin-left: 40px">
        <el-select :value="sinister.status" placeholder="Seleccionar un estado" no-data-text="Sin estados para mostrar"
          @change="changeProperty($event, 'status')">
          <el-option v-for="(state, index) in states" :key="index" :value="state" :label="state">
            <el-tag :type="stateType(state)">{{ state }}</el-tag>
          </el-option>
        </el-select>
      </div>
      <div style="font-weight: bold;">TIPO DE ROBO: {{  sinister.stolenType }} </div>
    </div>
    <ps-form-abm @save="save" @cancel="cancel" :disabledButton="disabledButton">
      <div class="column">
        <div class="row">
          <div class="column flex-row-align-left size-mmm">
            <label for="code">Fecha robo</label>
            <el-date-picker id="fechaRobo" type="datetime" :value="sinister.stealingDatetime"
              placeholder="Seleccionar fecha y hora del robo" @input="changeProperty($event, 'stealingDatetime')"
              :picker-options="pickerOptions" clearable="" format="dd/MM/yyyy HH:mm" value-format="yyyy-MM-ddTHH:mm:ss">
            </el-date-picker>
          </div>

          <div class="column flex-row-align-left size-mm">
            <label for="code">Provincia</label>
            <el-select :value="sinister.Province" placeholder="Seleccionar una provincia" value-key="province"
              no-data-text="Sin provincias para mostrar" @change="changeProperty($event, 'Province')" filterable>
              <el-option v-for="(province, index) in provinces" :key="index" :value="province"
                :label="province.province"></el-option>
            </el-select>
          </div>

          <div class="column flex-row-align-left size-mm">
            <label for="code">Localidad</label>
            <el-select :value="sinister.Locality" placeholder="Seleccionar una localidad" value-key="locality"
              :disabled="!sinister.Province.id ? true : false" no-data-text="Sin localidades para mostrar"
              @change="changeProperty($event, 'Locality')" filterable>
              <el-option v-for="(locality, index) in localities" :key="index" :value="locality"
                :label="locality.locality"></el-option>
            </el-select>
          </div>

          <div class="column flex-row-align-left">
            <label>Localidad texto libre</label>
            <el-input placeholder="Localidad texto libre" class="size-l" :disabled="true" :value="sinister.localityFreeText"
              @input="changeProperty($event, 'localityFreeText')"></el-input>
          </div>

          <div class="column flex-row-align-left">
            <label>Zona del robo</label>
            <el-input placeholder="Zona del robo" class="size-l" :value="sinister.stealingZone"
              @input="changeProperty($event, 'stealingZone')"></el-input>
          </div>

        </div>

        <div class="row">
          <div class="column flex-row-align-left size-mm">
            <label for="code" style="height: 19px;">&nbsp;</label>
            <el-checkbox border label="Es compañia" :value="sinister.isCompany"
              @change="changeProperty($event, 'isCompany')" class="size-mm"></el-checkbox>
          </div>

          <template v-if="sinister.isCompany == true">
            <div class="column flex-row-align-left size-mm">
              <label for="code">Cía Aseguradora</label>
              <el-select :value="sinister.Company" placeholder="Seleccionar una compañía" value-key="company"
                no-data-text="Sin compañías para mostrar" @change="changeProperty($event, 'Company')">
                <el-option v-for="(company, index) in companies" :key="index" :value="company"
                  :label="company.company"></el-option>
              </el-select>
            </div>

            <div class="column flex-row-align-left size-mm">
              <label for="code" style="height: 19px;">&nbsp;</label>
              <el-checkbox border label="Asegurado por robo" :value="sinister.insuredForStealing"
                @change="changeProperty($event, 'insuredForStealing')" class="size-mm"></el-checkbox>
            </div>

            <div class="column flex-row-align-left size-s">
              <label for="code" style="height: 19px;">&nbsp;</label>
              <el-checkbox border label="Tiene rastreo" :value="sinister.hasTracking"
                @change="changeProperty($event, 'hasTracking')" class="size-s"></el-checkbox>
            </div>
          </template>

          <div class="column flex-row-align-left size-mm" style="margin-right: 0;">
            <label for="code">Código seguimiento</label>
            <el-input disabled placeholder="Código de seguimiento" :value="sinister.trackingCode"
              @input="changeProperty($event, 'trackingCode')" style="width: 207px"></el-input>
          </div>
        </div>

        <div class="row">
          <div class="column flex-row-align-left size-mm">
            <label>Nombre del cliente</label>
            <el-input placeholder="Nombre del cliente" :value="sinister.clientName"
              @input="changeProperty($event, 'clientName')"></el-input>
          </div>
          <div class="column flex-row-align-left size-mm">
            <label>Apellido del cliente</label>
            <el-input placeholder="Apellido del cliente" :value="sinister.clientSurname"
              @input="changeProperty($event, 'clientSurname')"></el-input>
          </div>
          <div class="column flex-row-align-left size-mm">
            <label>Teléfono del cliente</label>
            <el-input placeholder="Teléfono del cliente" :value="sinister.clientTelephone"
              @input="changeProperty($event, 'clientTelephone')"></el-input>
          </div>
          <div class="column flex-row-align-left size-mm">
            <label>2do Teléfono del cliente</label>
            <el-input placeholder="2do Teléfono del cliente" :value="sinister.clientTelephone2"
              @input="changeProperty($event, 'clientTelephone2')"></el-input>
          </div>
          <div class="column flex-row-align-left">
            <label>Email del cliente</label>
            <el-input placeholder="Email del cliente" class="size-l" :value="sinister.clientEmail"
              @input="changeProperty($event, 'clientEmail')"></el-input>
          </div>
        </div>
        <div class="row">
          <el-input :value="sinister.observations" @input="changeProperty($event, 'observations')"
            placeholder="Observaciones" style="width: 100%; margin-right: 15px"></el-input>
        </div>
        <div class="showImagesLink" v-if="sinister.id">
          <el-link @click.native.prevent="showImages = !showImages"><i class="el-icon-view el-icon--right"></i> Mostrar
            imagenes </el-link>
        </div>
        <ps-images-sinister v-if="sinister.id && showImages == true"></ps-images-sinister>

      </div>
    </ps-form-abm>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import PsFormAbm from '@/components/PsFormAbm.vue'
import PsImagesSinister from './PsImagesSinister.vue';
import { locality } from '../../locality/locality_model';

export default {
  name: 'PsSinisterEdit',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let ahora = new Date()
          let hoy = new Date(ahora.getFullYear(), ahora.getMonth(), ahora.getDate() + 1, 0, 0, 0, 0)
          return time.getTime() >= hoy.getTime()
        }
      },
      showImages: false
    }
  },
  components: { PsFormAbm, PsImagesSinister },
  computed: {
    ...mapState('sinisters', ['sinister', 'errors', 'states']),
    ...mapState('localities', ['localities']),
    ...mapState('provinces', ['provinces']),
    ...mapState('companies', ['companies']),
    ...mapGetters('sinisters', ['stateType']),

    disabledButton() { return false; },
    listLocalities() {
      return this.sinister.Locality.idProvince ? this.localities.filter(l => l.idProvince == this.sinister.Locality.idProvince) : [];
    }
  },
  methods: {
    ...mapMutations('sinisters', ['SINISTERS_SINISTER_UNDEFINED', 'SINISTERS_ERROR_EMPTY', 'SINISTERS_SINISTER_PROPERTIES', 'SINISTERS_SINISTER_EMPTY']),
    ...mapMutations('localities', ['LOCALITIES_PAGE', 'LOCALITIES_LOCALITIES_EMPTY']),
    ...mapActions('sinisters', ['saveSinister', 'selectSinister']),
    ...mapActions('localities', ['selectLocalities']),
    cancel() {
      this.SINISTERS_SINISTER_EMPTY()
    },
    showValidationAlert() {
      let str = '<p>';
      if (this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', { confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true })
      }
    },
    async save() {
      try {
        const isNew = this.sinister.id ? false : true;
        this.SINISTERS_ERROR_EMPTY()
        let result = await this.saveSinister();
        if (isNew == true) {
          this.$notify({ title: 'Mensaje de confirmación', message: 'Siniestro creado', type: 'success' })
        } else {
          this.$notify({ title: 'Mensaje de confirmación', message: 'Siniestro actualizado', type: 'success' })
        }
        this.SINISTERS_SINISTER_EMPTY();

        setTimeout(async () => {
          this.$router.replace(`/home/sinisterDetail/${result.vehicleId}/${result.id}?${new Date().getTime()}`).catch(err => {console.log(err)})  
          // await this.selectSinister({ vehicleId: result.vehicleId, sinisterId: result.id, setSinister: false });
        }, 1000)
      } catch (err) {
        if (this.errors.length > 0) {
          this.showValidationAlert()
        }

        if (err.details != 'errores') {
          this.$notify({ title: 'Mensaje de error', message: `${err.details}`, type: 'error' })
        }
      }
    },
    changeProperty(value, property) {
      if (property == 'post') {
        this.SINISTERS_SINISTER_PROPERTIES({ property, value: !this.sinister.post })
      } else if (property == 'isCompany') {
        this.SINISTERS_SINISTER_PROPERTIES({ property, value: !this.sinister.isCompany })
      } else if (property == 'insuredForStealing') {
        this.SINISTERS_SINISTER_PROPERTIES({ property, value: !this.sinister.insuredForStealing })
      } else if (property == 'hasTracking') {
        this.SINISTERS_SINISTER_PROPERTIES({ property, value: !this.sinister.hasTracking })
      } else if (property == 'Province') {
        this.SINISTERS_SINISTER_PROPERTIES({ property, value })
        if (this.sinister.Locality && this.sinister.Locality.idProvince != value.id) {
          const cleanLocality = structuredClone(locality);
          this.SINISTERS_SINISTER_PROPERTIES({ property: 'Locality', value: cleanLocality });
          this.LOCALITIES_PAGE(0);
          this.LOCALITIES_LOCALITIES_EMPTY();
          this.selectLocalities({ idProvince: value.id });
        }    
      } else {
        this.SINISTERS_SINISTER_PROPERTIES({ property, value })
      }
    },
  }
}
</script>

<style scoped>
.row {
  margin-bottom: 15px;
}

.column {
  margin-right: 15px;
}

.psSinisterEdit {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px;
  background-color: #a5a5a555;
  border-radius: 6px;
}

.psSinisterEdit>.psFormAbm {
  padding-left: 0
}

.showImagesLink {
  width: 150px
}

.el-checkbox.is-bordered {
  border-color: #767676;
}

.el-checkbox.is-checked {
  border-color: #F706A1;
}

.el-select-dropdown__item {
  background-color: transparent;
}</style>