<template>
  <div class="psDato column flex-row-align-left" :class="size">
    <div class="label">{{ label }}</div>
    <div>{{ value }}</div>    
  </div>
</template>

<script>
export default {
  name: 'PsDato',
  props: {
    label: String, 
    value: String,
    size: {
      type: String,
      default: 'size-m'
    }
  }
}
</script>

<style scoped>
  .label { font-weight: 600;}
  .psDato { margin-bottom: 15px;}
</style>