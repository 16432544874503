<template>
  <el-button :type="type" size="small" plain :disabled="isDisabled"><i class="icon demo-icon" :class="icon" v-html="caracter"></i>{{ text }}</el-button>
</template>

<script>
export default {
  name: 'PsButton',
  props: {
    icon: String,
    text: String,
    type: String,
    disabled: Boolean,
    caracter: String
  },
  computed: {
    isDisabled () {
      return this.disabled
    }
  }
}
</script>

<style scoped>
  .icon{ margin-right: 10px;}
  .el-button.is-plain { transition: all 0.05s }
  .el-button.is-plain:visited, .el-button.is-plain:focus { color: #606266} 
  .el-button.is-plain:hover { border: 1px solid #000; transform: scale(1.02); }
  .el-button.is-plain { color: #000}
</style>  