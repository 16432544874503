import { pagination_params } from "../../shared/utils/pagination_params";

export const getParamsUrl = (params) => {
  if(!params) return '';
  let path = '';
  path = pagination_params(params);
  
  params.id && (path = `${path != '' ? path + '&' : ''}id=${params.id}`);
  params.domain && (path = `${path != '' ? path + '&' : ''}domain=${params.domain}`);
  params.recuperatorName && (path = `${path != '' ? path + '&' : ''}recuperatorName=${params.recuperatorName}`);
  params.recuperatorSurname && (path = `${path != '' ? path + '&' : ''}recuperatorSurname=${params.recuperatorSurname}`);
  params.recuperatorTelephone && (path = `${path != '' ? path + '&' : ''}recuperatorTelephone=${params.recuperatorTelephone}`);
  params.mark && (path = `${path != '' ? path + '&' : ''}mark=${params.mark}`);
  params.model && (path = `${path != '' ? path + '&' : ''}model=${params.model}`);
  params.chasisNumber && (path = `${path != '' ? path + '&' : ''}chasisNumber=${params.chasisNumber}`);
  return `?${path}`;
}